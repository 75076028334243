<div
  class="position-sticky sticky-top pt-4 pb-4 elemento-aggiorna shadow-custom test"
  [ngClass]="{
    'sticky-top-custom-modal': isInModal,
    'sticky-top-custom': !isInModal,
    'sticky-top-custom-sal': tabsOnOneRow
  }"
>
  <div class="row justify-content-center">


      <div [hidden]="readOnlyForm" class="text-center col-lg-3 col-md-3" [ngClass]="{ 'offset-lg-3': !gotoBtnOptions || !gotoBtnOptions.show }">
        <button
        (click)="openAddSubInterventimodal()"
        *ngIf="gotoBtnOptions && gotoBtnOptions.show"
        [disabled]="loading"
         class="btn btn-primary btn-block" type="button">
         Aggiungi Sub-Intervento&nbsp;<i class="fas fa-plus"></i>
        </button>
      </div>
      <div class=" text-center test2"
        [ngClass]="{ ' col-lg-4 col-md-6': !(gotoBtnOptions && gotoBtnOptions.showIcon) , 'col-lg-2' : gotoBtnOptions && gotoBtnOptions.showIcon}">
        <button
        (click)="gotoBackBtnClick()"
        *ngIf="gotoBtnOptions && gotoBtnOptions.show"
        [disabled]="loading"
          class="btn btn-secondary btn-block font-weight-bold"
          type="button">{{ gotoBtnOptions ? gotoBtnOptions.label : 'Torna a elenco' }}&nbsp;<i class="fas fa-times"
            *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"></i>
        </button>
      </div>
    </div>

</div>
<div class="row mx-0">
  <div class="titolo col-12">
    <h3 class="mt-3">Sub Interventi</h3>
  </div>
</div>

<div class="mt-5">
  <ngx-datatable-wrapper
    #ngxDatatableWrapper
    [templates]="templateList"
    style="width: 100%; height: auto"
    [dtOptions]="dtOptions"
  >
    <!-- Note Icon -->
    <ng-template #fieldNoteTemplate let-row="row" let-value="value">

      <ng-template #popTemplateNote>
          <div [hidden]="!value && !row.note" [innerHtml]="'<div>'+value+'</div>'"></div>
      </ng-template>

      &nbsp; <i class="fas font-size-18 text-secondary" [ngClass]="{
          ' fa-comment-slash': !value && !row.note,
          ' fa-comment text-info help px-1': value || row.note
        }"
        [popover]="value || row.note?  popTemplateNote:null" placement="left" triggers="mouseenter:mouseleave">
      </i>
    </ng-template>
    <!-- Codice field -->
    <ng-template #codiceSubInterventotemplate let-row="row" let-value="value" let-i="index">
      <span>{{ row.codice.substring(row.codice.indexOf('/')+1) }}</span>
    </ng-template>
    <!-- Edit button -->
    <ng-template #btnEditTemplate let-row="row" let-value="value">
      <a
        [ngClass]="{
          disabled: loading
        }"
        class="border-0 text-center"
        (click)="onEdit(row)"
        data-toggle="tooltip"
        data-placement="top"
        title="{{readOnlyForm?'dettaglio':'modifica dati'}}"
        >&nbsp;&nbsp;&ensp;<i class="fa pointer"  [ngClass]="{'fa-edit': !readOnlyForm,'fa-eye': readOnlyForm,'detail-icon': readOnlyForm}"></i
      ></a>
    </ng-template>
    <!-- Delete button -->
    <ng-template #btnDeleteTemplate let-row="row" let-value="value">
      <a
        [ngClass]="{
          disabled: loading
        }"
        class="border-0 text-center"
        (click)="onDelete(row)"
        data-toggle="tooltip"
        data-placement="top"
        title="elimina"
        [hidden]="readOnlyForm"
        >&nbsp;&nbsp;&ensp;<i class="far fa-trash-alt pointer"></i
      ></a>
    </ng-template>

    <ng-template #fieldDocumentazioneTemplate let-row="row" let-value="value">
        <i class="fas fa-file font-size-18 " [ngClass]="{
                        ' text-secondary': !value,
                        ' text-success text-info help px-1': value
                      }"
          popover="{{value  ? 'Documentazione presente':'Documentazione non presente'}}"
          placement="left" triggers="mouseenter:mouseleave"></i>
      </ng-template>
  </ngx-datatable-wrapper>
</div>

<ng-template #subInterventoModal>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">{{ modalTitle }}</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModal()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editForm" (ngSubmit)="onSubmit()">
      <input type="hidden" [formGroup]="editForm" formControlName="id" />
      <div class="form-row">
        <div class="form-group col-md-6" *ngIf="isInEdit">
          <h5>
            <label for="codice">
              Codice sub-intervento:
            </label>
          </h5>
          <input
            [formGroup]="editForm"
            type="text"
            class="form-control"
            id="codice"
            formControlName="codice"
            [readonly]="isInEdit"
          />
          <div
            *ngIf="submitted && f.codice.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.codice.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>

        <ng-template #popoverContentRupSub>
          <span>Responsabile unico del procedimento / Referente</span>
        </ng-template>

        <div class="form-group col-md-12">
          <label for="rupSelect">
            <h5 class="mandatory">
              <a
                type="button"
                class="help"
                [popover]="popoverContentRupSub"
                placement="top"
                triggers="mouseenter:mouseleave"
              >
                <i
                  class="far fa-question-circle text-0083e0 help"
                  style="font-size: 14px"
                ></i>

              </a>  &nbsp;RUP/Referente sub-intervento:
            </h5>
          </label>

          <div [ngClass]="{ 'is-invalid': submitted && f.rupSelect.errors }">
            <ng-select
              [items]="rupItems"
              bindValue="id"
              bindLabel="label"
              placeholder="Seleziona rup "
              formControlName="rupSelect"
            >
            </ng-select>
          </div>
          <div
            *ngIf="submitted && f.rupSelect.errors"
            class="invalid-feedback"
          >
              <div *ngIf="f.rupSelect.errors.required">
                Campo obbligatorio
              </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="descrizione">
            <h5>Descrizione del sub-intervento:</h5>
          </label>
          <textarea
            [formGroup]="editForm"
            formControlName="descrizione"
            class="form-control"
            id="formControlDescrizioneSubIntervento"
            rows="2"
          ></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="cig">
            <h5 class="mandatory">Cig:</h5>
          </label>
          <input
            [formGroup]="editForm"
            formControlName="cig"
            type="text"
            class="form-control"
            id="inputCig"
            [ngClass]="{
              'is-invalid': submitted && f.cig.errors
            }"
          />
          <div
            *ngIf="submitted && f.cig.errors"
            class="invalid-feedback"
          >
              <div *ngIf="f.cig.errors.required">
                Campo obbligatorio
              </div>
          </div>
        </div>
      </div>


      <div class="form-row" >
        <div
          class="form-group col-lg-6"
          [ngClass]="{
            'is-invalid': submitted && f.isErogazione.errors
          }"
        >
          <label for="" class="">
            <h5 class="">
              Erogazione
            </h5>
          </label>
          <div>
            <div class="form-check form-check-inline">
              <input
                id="campoPagamentoErogazione1"
                formControlName="isErogazione"
                type="radio"
                value="1"
                class="form-check-input"
                [ngClass]="{
                  'is-invalid':submitted && f.isErogazione.errors
                }"
              />
  
              <label for="campoPagamentoErogazione1" class="form-check-label"
                >Si</label
              >
            </div>
            <div class="form-check form-check-inline">
              <input
                
                id="campoPagamentoErogazione2"
                formControlName="isErogazione"
                type="radio"
                value="0"
                class="form-check-input"
                [ngClass]="{
                  'is-invalid': submitted && f.isErogazione.errors
                }"
              />
              <label for="campoPagamentoErogazione2" class="form-check-label"
                >No</label
              >
            </div>
          </div>
        </div>
      </div>



      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="inputQTESubIntervento">
            <h5 class="mandatory">Importo QTE sub-intervento:</h5>
          </label>
          <input
            [formGroup]="editForm"
            formControlName="importoQte"
            type="text"
            class="form-control"
            id="inputQTESubIntervento"
            [ngClass]="{
              'is-invalid': submitted && f.importoQte.errors
            }"
            [imask]="mask"
            [unmask]="true"
          />
          <div
            *ngIf="submitted && f.importoQte.errors"
            class="invalid-feedback"
          >
              <div *ngIf="f.importoQte.errors.required">
                Campo obbligatorio
              </div>
          </div>
          <div
            *ngIf="submitted && f.importoQte.errors"
            class="invalid-feedback"
          >
              <div *ngIf="f.importoQte.errors.maggAnnualita">
                La somma degli importi QTE dei sub interventi è maggiore della sommatoria dell'importo dell'annualità
              </div>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="inputAggiudicazioneSubIntervento">
            <h5>Importo aggiudicazione sub-intervento:</h5>
          </label>
          <input
          [formGroup]="editForm"
          formControlName="importoAggiudicazione"
            type="text"
            class="form-control"
            id="inputAggiudicazioneSubIntervento"
            [imask]="mask"
            [unmask]="true"
          />
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="dataStipulaContratto">
            <h5>Data stipula contratto sub-intervento:</h5>
          </label>
          <div
            [ngClass]="{
              'is-invalid': submitted && f.dataStipulaContratto.errors
            }"
            class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex px-0"
          >
            <input
              [formGroup]="editForm"
              formControlName="dataStipulaContratto"
              type="text"
              class="form-control"
              bsDatepicker
              [bsConfig]="{
                isAnimated: true,
                dateInputFormat: 'DD-MM-YYYY',
                adaptivePosition: true,
                containerClass: 'theme-dark-blue'
              }"
              [maxDate]="dataConsegnaValue"
              (bsValueChange)="setDataStipula($event)"
              #dataStipulaContratto="bsDatepicker"
              style="
                border-bottom-right-radius: unset;
                border-top-right-radius: unset;
              "
            />
            <button
              class="btn btn-primary"
              (click)="dataStipulaContratto.toggle()"
              type="button"
              style="
                border-bottom-left-radius: unset;
                border-top-left-radius: unset;
              "
            >
              <i class="fas fa-calendar-alt"></i>
            </button>

          </div>
          <div
            *ngIf="submitted && f.dataStipulaContratto.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.dataStipulaContratto.errors.required">
              Campo obbligatorio
            </div>
            <div *ngIf="f.dataStipulaContratto.errors.dataStipulaHigher">
              Data stipula deve essere minore o uguale della data consegna
            </div>
          </div>
        </div>
        <div class="form-group col-md-6">
          <label for="dataConsegnaContratto">
            <h5>Data consegna lavori sub-intervento:</h5>
          </label>
          <div
            [ngClass]="{
              'is-invalid': submitted && f.dataConsegnaContratto.errors
            }"
            class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex px-0"
          >
            <input
              [formGroup]="editForm"
              formControlName="dataConsegnaContratto"
              type="text"
              class="form-control"
              bsDatepicker
              [formGroup]="editForm"
              [minDate]="dataConsegnaMinValue"
              [maxDate]="dataConsegnaMaxValue"
              [bsConfig]="{
                isAnimated: true,
                dateInputFormat: 'DD-MM-YYYY',
                adaptivePosition: true,
                containerClass: 'theme-dark-blue'
              }"
              #dataConsegnaContratto="bsDatepicker"
              style="
                border-bottom-right-radius: unset;
                border-top-right-radius: unset;
              "
              (bsValueChange)="setDataConsegna($event)"
            />
            <button
              class="btn btn-primary"
              (click)="dataConsegnaContratto.toggle()"
              type="button"
              style="
                border-bottom-left-radius: unset;
                border-top-left-radius: unset;
              "
            >
              <i class="fas fa-calendar-alt"></i>
            </button>
          </div>
          <div
            *ngIf="submitted && f.dataConsegnaContratto.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.dataConsegnaContratto.errors.required">
              Campo obbligatorio
            </div>
          </div>
          <div
            *ngIf="submitted && f.dataConsegnaContratto.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.dataConsegnaContratto.bsDate && f.dataConsegnaContratto.errors.bsDate.minDate">
              Data Consegna Contratto non puo' essere minore della data stipula contratto
            </div>
          </div>
          <div
            *ngIf="submitted && f.dataConsegnaContratto.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.dataConsegnaContratto.bsDate && f.dataConsegnaContratto.bsDate.maxDate">
              Data Consegna Contratto non puo' essere maggiore della data conclusione lavori
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-6">
          <h5>
            <label for="inputDurataContrattoSubIntervento">
              Durata contrattuale sub-intervento (n. gg):</label>
          </h5>
          <input
            [formGroup]="editForm"
            formControlName="durataContrattuale"
            type="number"
            [ngClass]="{
              'is-invalid': submitted && f.durataContrattuale.errors
            }"
            class="form-control"
            id="inputDurataContrattoSubIntervento"
            (change)="setDurataContrattuale()"
          />
          <div
            *ngIf="submitted && f.durataContrattuale.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.durataContrattuale.errors.required">
              Campo obbligatorio
            </div>
            <div *ngIf="f.durataContrattuale.errors.min">
              Questo campo deve essere positivo
            </div>
          </div>
        </div>

        <div class="form-group col-md-6">
          <label for="dataFineLavoriPrev">
            <h5>Fine lavori prevista:</h5>
          </label>
          <div
            class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex px-0"
          >
            <input
              [formGroup]="editForm"
              formControlName="dataFineLavoriPrev"
              type="text"
              class="form-control"

              bsDatepicker
              [bsConfig]="{
                isAnimated: true,
                dateInputFormat: 'DD-MM-YYYY',
                adaptivePosition: true,
                containerClass: 'theme-dark-blue'
              }"
              #dataFineLavoriPrev="bsDatepicker"
              style="
                border-bottom-right-radius: unset;
                border-top-right-radius: unset;
              "
            />
            <button
              class="btn btn-primary"
              (click)="dataFineLavoriPrev.toggle()"
              disabled
              type="button"
              style="
                border-bottom-left-radius: unset;
                border-top-left-radius: unset;
              "
            >
              <i class="fas fa-calendar-alt"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="statoAttuazione">
            <h5 class="">Stato di attuazione sub-intervento:</h5>
          </label>
          <div
            [ngClass]="{ 'is-invalid': submitted && f.statoAttuazione.errors }"
          >
            <ng-select
              [items]="statiAttuazioneItems"
              formControlName="statoAttuazione"
              bindValue="id"
              bindLabel="descrizione"
              placeholder="Seleziona"
            >
            </ng-select>
          </div>
          <div
            *ngIf="submitted && f.statoAttuazione.errors"
            class="invalid-feedback"
          >
            <div *ngIf="f.statoAttuazione.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>
        <div class="form-group col-md-6">
          <div class="">
              <h5>
                <a type="button" *ngIf="isInEdit && storiaImportiSal" title="clicca per leggere la storia delle modifiche" class=""
                    placement="right" delay="300" [popover]="popTemplate">

                    <i class="fas fa-info-circle font-size-18 text-warning pr-1"></i>
                </a>
                <ng-template #popTemplate>
                  <div class=" scroll-h pr-1 " style="max-height: 400px;">
                    <ul class="list-group mt-2">
                      <li class="list-group-item py-1 mb-2" *ngFor="let item of storiaImportiSal; index as i">
                        <span class="bold">
                          Importo Sal Modificato
                        </span><br />
                        <span>{{printValueAsDateTime(item.dataCorrente)}} : {{printValueAsCurrency(item.importoSalCorrente)}}</span></li>
                    </ul>
                  </div>
                </ng-template>
                <label for="importoSal">

                  Importo SAL erogati sub-intervento:</label
                >
              </h5>
            <input
              [formGroup]="editForm"
              formControlName="importoSal"
              maxlength="255"
              type="text"
              class="form-control"
              placeholder="Inserisci l'importo"
              [ngClass]="{
                'is-invalid': submitted && (f.importoSal.errors)
              }"
              [imask]="mask"
              [unmask]="true"
            />
            <div
              *ngIf="submitted && f.importoSal.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.importoSal.errors.required">
                Campo obbligatorio
              </div>
            </div>
            <div
              *ngIf="submitted && f.importoSal.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.importoSal.errors.minPrecedente">
                L'importo Sal non puo' essere minore di quello precedentemente inserito
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="dataConclusioneLavori">
            <h5>Data Conclusione lavori (effettiva):</h5>
          </label>
          <div
            [ngClass]="{
              'is-invalid': submitted && f.dataConclusioneLavori.errors
            }"
            class="col-lg-12 col-md-12 col-sm-12 col-12 d-flex px-0"
          >
            <input
              [formGroup]="editForm"
              formControlName="dataConclusioneLavori"
              type="text"
              class="form-control"
              bsDatepicker
              [bsConfig]="{
                isAnimated: true,
                dateInputFormat: 'DD-MM-YYYY',
                adaptivePosition: true,
                containerClass: 'theme-dark-blue'
              }"
              #dataConclusioneLavori="bsDatepicker"
              [minDate]="dataConsegnaValue"
              (bsValueChange)="setDataConclusione($event)"
              style="
                border-bottom-right-radius: unset;
                border-top-right-radius: unset;
              "
            />
            <button
              class="btn btn-primary"
              (click)="dataConclusioneLavori.toggle()"
              type="button"
              style="
                border-bottom-left-radius: unset;
                border-top-left-radius: unset;
              "
            >
              <i class="fas fa-calendar-alt"></i>
            </button>
          </div>
          <div
              *ngIf="submitted && f.dataConclusioneLavori.errors"
              class="invalid-feedback"
            >
              <div *ngIf="f.dataConclusioneLavori.errors.dataConclusioneLower">
                Data conclusione deve essere maggiore o uguale della data consegna
              </div>
            </div>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12">
          <label for="formControlDescrizioneSubIntervento">
            <h5>Note:</h5>
          </label>
          <textarea
            [formGroup]="editForm"
            formControlName="note"
            class="form-control"
            id="formControlDescrizioneSubIntervento"
            rows="2"
          ></textarea>
        </div>
      </div>

      <!--documentazione-->
<div [hidden]="!editForm || !editForm.controls || !editForm.controls.id.value">
    <div class=" mt-5">
        <hr>
      </div>

    <documentazione-component (onSubmitEmitter)="documentazioneChanged($event)"  [subInterventoId]="editForm.controls.id.value"
    [gotoBtnOptions]="{show:false,label:gotoBtnOptions?gotoBtnOptions.label:''}"
    >
  </documentazione-component>
</div>
<!--fine documentazione-->
      <button [hidden]="readOnlyForm" type="submit" class="btn btn-primary btn-block" [disabled]="loading">
        <img *ngIf="loading " class="pl-3 float-right "
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        {{submitLabel}}
      </button>
    </form>
  </div>
</ng-template>
