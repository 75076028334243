import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { AppConfigService } from "src/app/services/app-config.service";
import { SubIntervento } from '../models';

class DataTablesResponse {
  data: SubIntervento[];
  draw: number;
  recordsFiltered: number;
  recordsTotal: number;
}

@Injectable({
  providedIn: 'root'
})
export class SubInterventiService {
  private apiUrl = `${AppConfigService.env.api_endpoint}${AppConfigService.env.endpoints.inframob}SubInterventi/`;

  constructor(private http: HttpClient) { }
  getAlldt(dataTablesParameters: any, interventoId:number) {
    return this.http.post<DataTablesResponse>(
        `${this.apiUrl}interventi/${interventoId}/sub-interventi/alldt/`,
        dataTablesParameters
    );
  }

getAllByInterventoId(interventoId:number){
  return this.http.get<any[]>(`${this.apiUrl}interventi/${interventoId}/sub-interventi/`);
}

  getAll() {
      return this.http.get<SubIntervento[]>(`${this.apiUrl}`);
  }
  insert(s: any, interventoId: number) {
      return this.http.post<any>(`${this.apiUrl}interventi/${interventoId}/sub-interventi/`, s);
  }
  update(s: any, interventoId: number) {
      return this.http.put<any>(`${this.apiUrl}interventi/${interventoId}/sub-interventi/${s.id}/`, s);
  }
  delete(id:number, interventoId: number) {
      return this.http.delete<any>(`${this.apiUrl}interventi/${interventoId}/sub-interventi/${id}/`);
  }
  save(s:any, interventoId: number){
    if (s.id) return this.update(s, interventoId);
    else return this.insert(s, interventoId);
  }
}
