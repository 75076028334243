import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  Input,
  EventEmitter,
  Output,
} from "@angular/core";
import { TabsetComponent } from "ngx-bootstrap/tabs";
import { DatiGeneraliComponent } from "../datiGenerali/dati-generali.component";
import { InterventoService } from "../../services";
import { FasiComponent } from "../fasi/fasi.component";
import { InterventoErogazioniComponent } from "../interventoErogazioni/intervento-erogazioni.component";
import { InterventoSalComponent } from "../interventoSal/intervento-sal.component";
import { AssociaProvvedimentiComponent } from "../associaProvvedimenti/associa-provvedimenti.component";
import { AssociaAnnualitaComponent } from "../associaAnnualita/associa-annualita.component";
import { InterventoFinanziamentoComponent } from "../interventoFinanziamento/intervento-finanziamento.component";
import { DocumentazioneComponent } from "../documentazione/documentazione.component";
import { ProgettoDivDettaglioService } from "../progettoDivDettaglio/progetto-div-dettaglio.service";
import { RiepilogoInterventoComponent } from "../riepilogoIntervento/riepilogo-intervento.component";
import { StoricoInterventoComponent } from "../storicoIntervento/storico-intervento.component";
import { IsDirtyHelper } from "../../../../helpers/is-dirty.helper";
import { MonitoraggioComponent } from "../monitoraggio/monitoraggio.component";
import { DnshComponent } from "../dnsh/dnsh.component";
import { AssociaProvvedimentiDocumentiComponent } from "../associa-provvedimenti-documenti/associa-provvedimenti-documenti.component";
import { BdapComponent } from "../bdap/bdap.component";
import { ServiceType } from "../../models/service-type";
import { SicerBilancioComponent } from "../sicerBilancio/sicer-bilancio.component";
import { SubInterventiComponent } from "../sub-interventi/sub-interventi.component";
import { InterventiSismaComponent } from "../interventi-sisma/interventi-sisma.component";

@Component({
  selector: "intervento-component",
  templateUrl: "./intervento.component.html",
  styleUrls: ["./intervento.component.css"],
})
export class InterventoComponent implements OnInit, OnDestroy {
  @ViewChild("tabs") tabs: TabsetComponent;

  @ViewChild("tabDatiGeneraliProgettoContent", { static: true })
  tabDatiGeneraliContent: DatiGeneraliComponent;

  @ViewChild("tabFasiProgettoContent", { static: true })
  tabFasiProgettoContent: FasiComponent;

  @ViewChild("tabErogazioniContent", { static: true })
  tabErogazioniContent: InterventoErogazioniComponent;

  @ViewChild("tabSalContent", { static: true })
  tabSalContent: InterventoSalComponent;

  @ViewChild("tabFinanziamentiContent", { static: true })
  tabFinanziamentiContent: InterventoFinanziamentoComponent;
  @ViewChild("tabProvvedimentiContent", { static: true })
  tabProvvedimentiContent: AssociaProvvedimentiComponent;
  @ViewChild("tabAnnualitaContent", { static: true })
  tabAnnualitaContent: AssociaAnnualitaComponent;
  @ViewChild("tabDocumentazioneContent", { static: true })
  tabDocumentazioneContent: DocumentazioneComponent;

  @ViewChild("tabRiepilogoInterventoContent", { static: true })
  tabRiepilogoInterventoContent: RiepilogoInterventoComponent;

  @ViewChild("divTemplateStorico", { static: true })
  divTemplateStorico: StoricoInterventoComponent;

  @ViewChild("divTemplateMonitoraggio", { static: true })
  divTemplateMonitoraggio: MonitoraggioComponent;

  @ViewChild("divTemplateDnsh", { static: true })
  divTemplateDnsh: DnshComponent;

  @ViewChild("divTemplateBdap", { static: true })
  divTemplateBdap: BdapComponent;

  @ViewChild("divSicerBilancioContent", { static: true })
  divSicerBilancioContent: SicerBilancioComponent;

  @ViewChild("divSubInterventi", { static: true })
  divSubInterventi: SubInterventiComponent;

  @ViewChild("divSismaContent", { static: true })
  divSismaContent: InterventiSismaComponent;

  @ViewChild("divProvvedimentiDocumentiContent", { static: true })
  divProvvedimentiDocumentiContent: AssociaProvvedimentiDocumentiComponent;

  cup: string;
  _interventoData: any;
  interventoId: number = null;
  isInInsertState = true;
  type: ServiceType = ServiceType.INTERVENTO;

  sismaHidden: boolean = true;

  @Output() datiGeneraliSavedEmitter: EventEmitter<any> = new EventEmitter();
  @Output() closeModalEmitter: EventEmitter<any> = new EventEmitter();

  datiDettaglioIntervento: any;
  datiDettaglioProgetto: any;

  codiceIntervento: any;

  finanziamentiIntervento: any;

  areeTematiche: any;

  @Input()
  set interventoData(value) {
    this.interventoId = null;
    this._interventoData = value;
    const dataParsed = JSON.parse(this._interventoData);

    this.cup = dataParsed.cup;
    this.datiDettaglioIntervento = dataParsed.dataIntervento;
    this.datiDettaglioProgetto = dataParsed.dataProgetto;

    this.codiceIntervento = this.datiDettaglioIntervento.codice;

    this.interventoId = this._interventoData ? dataParsed.interventoId : null;
    this.isInInsertState = !value || !this.interventoId;

    this.getInterventoFinanziamentiById(this.interventoId);
    this.interventoService.get(this.interventoId).subscribe((resp) => {
      this.exsistAreeTematiche(resp);
    });

    if (this.progettoDivDettaglioService)
      window.setTimeout(
        () =>
          this.progettoDivDettaglioService.setInterventoId(this.interventoId),
        10
      );
    if (this.progettoDivDettaglioService)
      window.setTimeout(() => this.progettoDivDettaglioService.reload(), 10);
  }
  get interventoData() {
    return this._interventoData;
  }

  @Input()
  set projectId(value: number) {
    window.setTimeout(() => {
      if (this.progettoDivDettaglioService)
        window.setTimeout(
          () => this.progettoDivDettaglioService.setId(value),
          10
        );
    }, 10);
  }

  datiProgetto: any;
  @Input()
  set projectData(data: any) {
    this.datiProgetto = data;
  }

  constructor(
    private progettoDivDettaglioService: ProgettoDivDettaglioService,
    private isDirtyHelper: IsDirtyHelper,
    private interventoService: InterventoService
  ) {}

  ngOnDestroy(): void {
    if (this.progettoDivDettaglioService)
      window.setTimeout(() => {
        this.progettoDivDettaglioService.setInterventoId(null);
        this.progettoDivDettaglioService.setId(null);
      }, 10);
    
  }
  ngOnInit(): void {
    
  }

  confirmChangeTab($event, index) {
    if (!this.isDirtyHelper.checkIsDirty()) {
      $event.stopPropagation();
      return false;
    }

    if (index !== 15 && this.activeTab === "tabSicerBilancio") {
      this.divSicerBilancioContent.resetSubData();
    }
    switch (index) {
      case 0:
        this.activeTab = "tabDatiGenerali";
        if (this.tabDatiGeneraliContent)
          this.tabDatiGeneraliContent.reloadData();
        break;
      case 1:
        this.activeTab = "tabFasi";
        if (this.tabFasiProgettoContent)
          this.tabFasiProgettoContent.reloadData();
        break;
      case 2:
        this.activeTab = "tabFinanziamenti";
        if (this.tabFinanziamentiContent) {
          this.tabFinanziamentiContent.reloadData();
        }
        break;
      case 3:
        this.activeTab = "tabErogazioni";
        if (this.tabErogazioniContent) {
          this.tabErogazioniContent.reloadData();          
        }
        break;
      case 4:
        this.activeTab = "tabSal";
        if (this.tabSalContent) this.tabSalContent.reloadData();
        break;
      case 6:
        this.activeTab = "tabProvvedimenti";
        if (this.tabProvvedimentiContent)
          this.tabProvvedimentiContent.reloadData();
        break;
      case 7:
        this.activeTab = "tabAnnualita";
        if (this.tabAnnualitaContent) this.tabAnnualitaContent.reloadData();
        break;
      case 8:
        this.activeTab = "tabDocumentazione";
        if (this.tabDocumentazioneContent)
          this.tabDocumentazioneContent.refreshGrid();
        break;
      case 9:
        this.activeTab = "tabRiepilogo";
        if (this.tabRiepilogoInterventoContent)
          this.tabRiepilogoInterventoContent.reloadData();
        break;
      case 10:
        this.activeTab = "tabStorico";
        if (this.divTemplateStorico) this.divTemplateStorico.reloadAllData();
        break;
      case 11:
        this.activeTab = "tabMonitoraggio";
        if (this.divTemplateMonitoraggio)
          this.divTemplateMonitoraggio.reloadAllData();
        break;
      case 12:
        this.activeTab = "tabDnsh";
        if (this.divTemplateDnsh) this.divTemplateDnsh.reloadAllData();
        break;
      case 13:
        this.activeTab = "tabProvvedimentiDocumenti";
        if (this.divProvvedimentiDocumentiContent)
          this.divProvvedimentiDocumentiContent.reloadAllData();
        break;
      case 14:
        this.activeTab = "tabBdap";
        if (this.divTemplateBdap) this.divTemplateBdap.reloadAllData();
        break;
      case 15:
        this.activeTab = "tabSicerBilancio";
        if (this.divSicerBilancioContent)
          this.divSicerBilancioContent.reloadAllData();
        break;
      case 16:
        this.activeTab = "tabSubInterventi";
        if (this.divSubInterventi) this.divSubInterventi.reloadAllData();
        break;
      case 17:
        this.activeTab = "tabSisma";
        if (this.divSismaContent) this.divSismaContent.reloadAllData();
        break;
    }
  }
  activeTab: string = "tabDatiGenerali";

  datiGeneraliSaved(data) {
    console.log(`inside datiGeneraliSaved with : ${JSON.stringify(data)}`);
    this.datiGeneraliSavedEmitter.emit(data);
    this.getInterventoDataById(data.id);
    this.exsistAreeTematiche(data);
  }

  salSaved(data) {
    console.log("into salSaved");    
  }
  erogazioneSaved(data) {
    console.log("into erogazioneSaved");    
  }

  closeModal(data) {
    if (!this.isDirtyHelper.checkIsDirty()) {
      return false;
    }
    this.interventoId = null;
    this.closeModalEmitter.emit(data);
  }

  interventoObj = null;
  getInterventoDataById(id) {
    if (!this.interventoId && !id) {
      this.interventoObj = null;
      return;
    }
    id = this.interventoId ?? id;
    this.interventoService.getDatiDettaglio(id).subscribe((x) => {
      this.interventoObj = x;
    });    
  }

  getInterventoFinanziamentiById(id) {
    this.interventoService.getFinanziamenti(id).subscribe((result) => {
      this.finanziamentiIntervento = result;
    });
  }

  exsistAreeTematiche(data: any) {
    if (data.areeTematiche.find((el) => el.struttura == "DU0100")) {
      this.sismaHidden = false;
    } else {
      this.sismaHidden = true;
    }
  }
}
