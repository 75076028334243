<div class="p-5 container-xl text-center" [hidden]="loadDataCounter <= 0">
  <app-loading message="Caricamento in corso..."></app-loading>
</div>

<div
  class="position-sticky sticky-top pt-4 pb-4 elemento-aggiorna shadow-custom"
  [ngClass]="{
    'sticky-top-custom': !interventoData,
    'sticky-top-custom-modal': interventoData,
    'sticky-top-custom-sal': esistonoInterventi,
    'sticky-top-custom-modal-new':
      currentProject && !currentProject['id'] && isIntervento,
    'sticky-top-custom-new-progetto':
      currentProject && !currentProject['id'] && !isIntervento
  }"
  [hidden]="loadDataCounter > 0"
>
  <div class="row justify-content-center">
    <div
      class="text-center col-lg-2 col-md-2"
      [ngClass]="{
        ' offset-lg-3': !gotoBtnOptions || !gotoBtnOptions.show
      }"
      [hidden]="readOnlyForm"
    >
      <button
        [disabled]="loading"
        (click)="onSubmit()"
        class="btn btn-primary btn-block"
        type="button"
      >
        Salva&nbsp;<i class="fas fa-save"></i>
        <img
          alt="loading"
          *ngIf="loading"
          class="pl-3 float-right"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      </button>
    </div>

    <div
      class="text-center col-lg-2 col-md-4"
      *ngIf="currentProject && currentProject.id"
    >
      <button
        [disabled]="loading"
        type="button"
        class="btn btn-info btn-block font-weight-bold"
        placement="bottom"
        delay="300"
        [popover]="popTemplate"
      >
        Esporta&nbsp;<i class="fas fa-file-export"></i>
        <img
          *ngIf="loading"
          alt="loading"
          class="pl-3 float-right"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      </button>
    </div>
    <ng-template #popTemplate>
      <button
        class="btn btn-danger btn-block font"
        type="button"
        (click)="esportaPdf()"
      >
        PDF&nbsp;<i class="far fa-file-pdf"></i>
      </button>

      <button
        class="btn btn-success btn-block"
        type="button"
        (click)="esportaExcel()"
      >
        EXCEL&nbsp;<i class="far fa-file-excel"></i>
      </button>

      <button
        class="btn btn-success btn-block"
        type="button"
        (click)="esportaExcelCompleto()"
      >
        EXCEL<br />COMPLETO&nbsp;<i class="far fa-file-excel"></i>
      </button>
    </ng-template>

    <div
      class="text-center col-lg-3 col-md-4"
      [ngClass]="{
        ' offset-lg-3': !gotoBtnOptions || !gotoBtnOptions.show
      }"
      [hidden]="!possoConcludere()"
    >
      <button
        [disabled]="loading"
        (click)="onConcludiIntervento()"
        class="btn btn-success btn-block font-weight-bold"
        type="button"
      >
        Concludi intervento&nbsp;<i class="far fa-check-circle"></i>
        <img
          *ngIf="loading"
          alt="loading"
          class="pl-3 float-right"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
        />
      </button>
    </div>

    <div
      class="text-center"
      [ngClass]="{
        ' col-lg-4 col-md-6': !(gotoBtnOptions && gotoBtnOptions.showIcon),
        'col-lg-2': gotoBtnOptions && gotoBtnOptions.showIcon
      }"
    >
      <button
        (click)="gotoBackBtnClick()"
        *ngIf="gotoBtnOptions && gotoBtnOptions.show"
        [disabled]="loading"
        class="btn btn-secondary btn-block font-weight-bold"
        type="button"
      >
        {{ gotoBtnOptions ? gotoBtnOptions.label : "Torna a elenco" }}&nbsp;<i
          class="fas fa-times"
          *ngIf="gotoBtnOptions && gotoBtnOptions.showIcon"
        ></i>
      </button>
    </div>
  </div>
</div>
<!--***-->

<form [formGroup]="myGroup" novalidate [hidden]="loadDataCounter > 0">
  <div class="col-lg-12 mt-5">
    <div class="row" *ngIf="currentProject && currentProject.id">
      <div class="form-group col-sm-6">
        <label for="id">
          <h5>Id:</h5>
        </label>
        <input
          readonly
          [formGroup]="myGroup"
          formControlName="id"
          type="text"
          class="form-control"
        />
      </div>

      <div class="form-group col-sm-6">
        <label for="codice">
          <h5>Codice:</h5>
        </label>
        <input
          readonly
          [formGroup]="myGroup"
          maxlength="255"
          formControlName="codice"
          type="text"
          [ngClass]="{
            'form-control': true,
            'is-invalid': submitted && f.codice.errors
          }"
          placeholder="Inserisci il codice"
        />
        <div
          *ngIf="submitted && f.codice.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="f.codice.errors.required">Campo obbligatorio</div>
        </div>
      </div>
    </div>

    <div class="form-group row">
      <div class="col-lg-6 col-md-6 col-sm-12 col-12">
        <label for="pnrrRilevante">
          <h5 class="mandatory">Rilevante per PNRR:</h5>
        </label>
        <div>
          <div class="form-check form-check-inline">
            <label for="pnrrRilevante1" class="form-check-label">
              <input
                [formGroup]="myGroup"
                id="pnrrRilevante1"
                formControlName="pnrrRilevante"
                type="radio"
                value="1"
                class="form-check-input"
                [ngClass]="{
                  'is-invalid': submitted && f.pnrrRilevante.errors
                }"
                (change)="pnrrRilevanteChange()"
              />
              Si
            </label>
          </div>
          <div class="form-check form-check-inline">
            <label for="pnrrRilevante2" class="form-check-label">
              <input
                [formGroup]="myGroup"
                id="pnrrRilevante2"
                formControlName="pnrrRilevante"
                type="radio"
                value="0"
                class="form-check-input"
                [ngClass]="{
                  'is-invalid': submitted && f.pnrrRilevante.errors
                }"
                (change)="pnrrRilevanteChange()"
              />No
            </label>
          </div>
        </div>

        <div
          *ngIf="submitted && f.pnrrRilevante.errors"
          class="invalidFieldMessage"
          style="display: block"
        >
          <div
            *ngIf="f.pnrrRilevante.errors"
            class="invalid-feedback"
            style="display: block"
          >
            Campo obbligatorio
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-4" [hidden]="!showPnrrLineeFinanziamento()">
        <hr />
      </div>
      <div class="col-lg-12 mt-4" [hidden]="!showPnrrLineeFinanziamento()">
        <div class="row justify-content-between">
          <div class="col-lg-6 col-sm-6 col-6 mt-3">
            <h5 for="" class="">Linea finanziamento PNRR/PNC</h5>
          </div>
          <div
            [hidden]="
              readOnlyForm ||
              validato ||
              !currentProject['isMain'] ||
              bloccaCampiPnrr()
            "
            class="col-lg-1 col-sm-2 col-3 text-center mt-3"
          >
            <a
              class="bottone-inserisci"
              (click)="openModalPnrrLineeFinanziamento()"
            >
              <i class="fas fa-plus pointer"></i
            ></a>
          </div>
        </div>
      </div>
    </div>
    <div
      class="col-lg-12 mt-3 table-responsive-lg"
      [hidden]="!showPnrrLineeFinanziamento()"
    >
      <table class="table table-hover table-colore">
        <thead class="">
          <th class="px-1">codice</th>
          <th class="px-1">missione</th>
          <th class="px-1">componente</th>
          <th class="px-1">descrizione</th>
          <th class="px-1">misura</th>
          <th class="px-1">tipologia</th>
          <th class="px-1">quota pnrr</th>
          <th class="px-1">quota pnc</th>
          <th
            [hidden]="readOnlyForm || validato || !currentProject['isMain']"
            class="casella-icona-tx px-1"
          >
            elimina
          </th>
        </thead>
        <tbody>
          <ng-container *ngIf="pnrrLineaFinanziamentoSelezionata">
            <tr>
              <td class="small px-1">
                {{ pnrrLineaFinanziamentoSelezionata.codice }}
              </td>
              <td class="small px-1">
                {{ pnrrLineaFinanziamentoSelezionata.missione.descrizione }}
              </td>
              <td class="small px-1">
                {{ pnrrLineaFinanziamentoSelezionata.componente.descrizione }}
              </td>
              <td class="small px-1">
                {{ pnrrLineaFinanziamentoSelezionata.descrizione }}
              </td>
              <td class="small px-1">
                {{ pnrrLineaFinanziamentoSelezionata.misuraPnrr }}
              </td>
              <td class="small px-1">
                {{ pnrrLineaFinanziamentoSelezionata.tipologia }}
              </td>
              <td class="small px-1">
                {{
                  printValueAsCurrency(pnrrLineaFinanziamentoSelezionata.pnrr)
                }}
              </td>
              <td class="small px-1">
                {{ printValueAsCurrency(pnrrLineaFinanziamentoSelezionata.fc) }}
              </td>
              <td
                class="small px-1"
                [hidden]="
                  readOnlyForm ||
                  validato ||
                  !currentProject['isMain'] ||
                  bloccaCampiPnrr()
                "
              >
                <a
                  (click)="removePnrrLineaFinanziamento()"
                  href="javascript:;;"
                  data-toggle="tooltip"
                  data-placement="top"
                  title="elimina"
                  ><i class="far fa-trash-alt casella-icona-tx"></i>
                </a>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="!pnrrLineaFinanziamentoSelezionata">
            <td colspan="8">
              Nessuna linea di finanziamento PNRR/PNC selezionata
            </td>
          </tr>
        </tbody>
      </table>
      <div
        *ngIf="submitted && pnrrLineaFinanziamentoIsError"
        class="invalidFieldMessage"
      >
        <div class="invalid-feedback" style="display: block">
          Selezionare almeno una linea di finanziamento PNRR/PNC
        </div>
      </div>
    </div>

    <!--flag pnrr territoriale-->
    <div class="row" [hidden]="f.pnrrRilevante.value != '1'">
      <div class="form-group col-sm-6">
        <label for="" class="">
          <h5 class="d-inline-block">PNRR territoriale:</h5>
        </label>
        <div>
          <div class="custom-control custom-switch">
            <input
              type="checkbox"
              class="custom-control-input"
              id="customSwitchPnrrTerritoriale{{ randomNum }}"
              [formGroup]="myGroup"
              formControlName="pnrrTerritoriale"
            />
            <label
              class="custom-control-label"
              for="customSwitchPnrrTerritoriale{{ randomNum }}"
            ></label>
          </div>
        </div>
      </div>
    </div>
    <!--fine flag pnrr territoriale-->

    <div
      [ngClass]="{
        'bg-celeste container border  rounded shadow pt-3': !hideTipologia
      }"
    >
      <div
        class="row"
        [ngClass]="{
          'mb-4': submitted && f.type.errors && f.type.errors.required
        }"
      >
        <div *ngIf="!hideTipologia" class="form-group col-sm-6">
          <label for="natura">
            <h5 [ngClass]="{ mandatory: isTipologiaInterventoMandatory }">
              Natura intervento:
            </h5>
          </label>
          <div [ngClass]="{ 'is-invalid': submitted && f.natura.errors }">
            <ng-select
              [items]="naturaList"
              bindValue="key"
              bindLabel="value"
              placeholder="Seleziona natura "
              (change)="setNaturaIntervento($event)"
              (clear)="onNaturaInterventoClear()"
              formControlName="natura"
            >
            </ng-select>
          </div>
          <div
            *ngIf="submitted && f.natura.errors"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="f.natura.errors.required">Campo obbligatorio</div>
          </div>
        </div>

        <div *ngIf="!hideTipologia" class="form-group col-sm-6">
          <label for="natura">
            <h5 [ngClass]="{ mandatory: isTipologiaInterventoMandatory }">
              Tipologia intervento:
            </h5>
          </label>
          <div [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
            <ng-select
              [items]="types"
              bindValue="key"
              bindLabel="value"
              placeholder="Seleziona tipologia "
              (change)="setTipoIntervento($event)"
              (clear)="onTipologiaInterventoClear()"
              formControlName="type"
            >
            </ng-select>
          </div>
          <div
            *ngIf="submitted && f.type.errors"
            class="invalid-feedback"
            style="display: block"
          >
            <div *ngIf="f.type.errors.required">Campo obbligatorio</div>
          </div>
        </div>
      </div>
      <!--close row-->
      <!--Geolocalizzazione, visibili solo se senza interventi figli-->
      <div class="row">
        <div class="col-lg-12 mt-3" *ngIf="isIntervento || !esistonoInterventi">
          <div class="row justify-content-between" *ngIf="!hideTipologia">
            <div class="col-lg-6 col-sm-6 col-6 mt-3">
              <h5 for="" class="">Geolocalizzazione</h5>
            </div>
            <div
              class="col-lg-1 col-sm2 col-3 text-center mt-3"
              [hidden]="readOnlyForm"
            >
              <a
                class="bottone-inserisci pointer"
                *ngIf="f.natura.value"
                (click)="openModalGeolocalizzazione($event)"
                ><i class="fas fa-plus"></i
              ></a>
            </div>
          </div>
        </div>

        <div class="col-lg-12 mt-3 table-responsive-lg" *ngIf="!hideTipologia">
          <table class="table table-hover table-colore">
            <thead class="">
              <th>Provincia</th>
              <th>Comune</th>
              <th scope="col" class="casella-descrizione-tx">Indirizzo</th>
              <th>Dettaglio tipologia</th>
              <th *ngIf="currentNaturaKey != '03'">Quantità</th>
              <th scope="col" class="casella-icona-tx" [hidden]="readOnlyForm">
                elimina
              </th>
            </thead>
            <tbody>
              <ng-container
                *ngIf="
                  currentProject &&
                  currentProject.localizzazioni &&
                  currentProject.localizzazioni.length &&
                  currentProject.localizzazioni.length > 0
                "
              >
                <tr *ngFor="let item of currentProject.localizzazioni">
                  <td>{{ item.provincia ? item.provincia.name : "" }}</td>
                  <td>{{ item.comune ? item.comune.name : "" }}</td>
                  <td>{{ item.indirizzo }}</td>
                  <td>{{ item.aggregato ? item.aggregato.name : "" }}</td>
                  <td *ngIf="currentNaturaKey != '03'">{{ item.quantita }}</td>
                  <td [hidden]="readOnlyForm">
                    <a
                      (click)="removeGeolocalizzazione(item)"
                      href="javascript:;;"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="elimina"
                      ><i class="far fa-trash-alt casella-icona-tx"></i>
                    </a>
                  </td>
                </tr>
              </ng-container>
              <tr
                *ngIf="
                  !(
                    currentProject &&
                    currentProject.localizzazioni &&
                    currentProject.localizzazioni.length &&
                    currentProject.localizzazioni.length > 0
                  )
                "
              >
                <td colspan="5">Nessuna geolocalizzazione selezionata</td>
              </tr>
            </tbody>
          </table>
          <div
            *ngIf="
              submitted &&
              geolocalizzazioneIsError &&
              !currentProject.localizzazioni.length
            "
            class="invalidFieldMessage"
          >
            <div
              *ngIf="geolocalizzazioneIsError"
              class="invalid-feedback"
              style="display: block"
            >
              Inserire almeno una geolocalizzazione
            </div>
          </div>
        </div>

        <div
          class="form-group col-md-3 align-self-end"
          *ngIf="currentProject && currentProject.id"
        >
          <button
            class="btn btn-primary btn-block"
            [disabled]="!(esistonoInterventi || isFormTipologiaDisegnabile())"
            (click)="showMap()"
          >
            <span class="d-sm-none d-md-inline">Localizzazione&nbsp;</span
            ><i
              class="fas fa-map-marker-alt pointer"
              style="color: #fff; font-size: 18px"
            ></i>
          </button>
        </div>
      </div>
      <!--close row-->
    </div>

    <div class="row mt-4">
      <div class="form-group col-sm-12">
        <label for="descrizione">
          <h5 class="mandatory">Descrizione:</h5>
        </label>
        <textarea
          [formGroup]="myGroup"
          formControlName="descrizione"
          type="text"
          [ngClass]="{
            'form-control': true,
            'is-invalid': submitted && f.descrizione.errors
          }"
          placeholder="Inserisci la descrizione"
          rows="3"
        ></textarea>
        <div
          *ngIf="submitted && f.descrizione.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="f.descrizione.errors.required">Campo obbligatorio</div>
        </div>
      </div>
    </div>

    <div class="row" [hidden]="true">
      <div class="form-group col-sm-6">
        <label for="annoRiferimento" class="">
          <h5 class="mandatory">Anno di riferimento:</h5>
        </label>
        <input
          [formGroup]="myGroup"
          formControlName="annoRiferimento"
          type="text"
          mask="0000"
          [showMaskTyped]="true"
          [ngClass]="{
            'form-control': true,
            'is-invalid': submitted && f.annoRiferimento.errors
          }"
          placeholder="Inserisci l'anno di riferimento"
        />
        <div
          *ngIf="submitted && f.annoRiferimento.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="f.annoRiferimento.errors.required">
            Campo obbligatorio
          </div>
          <div *ngIf="f.annoRiferimento.errors.minlength">
            Anno di riferimento deve essere di 4 cifre
          </div>
          <div *ngIf="f.annoRiferimento.errors.range">
            Anno di riferimento deve essere maggiore di {{ minYear }}
          </div>
        </div>
      </div>

      <div class="form-group col-sm-6">
        <label for="codice">
          <h5>Codice direzione:</h5>
        </label>
        <input
          [formGroup]="myGroup"
          maxlength="255"
          formControlName="codiceDirezione"
          type="text"
          [ngClass]="{
            'form-control': true,
            'is-invalid': submitted && f.codiceDirezione.errors
          }"
          placeholder="Inserisci il codice direzione"
        />
        <div
          *ngIf="submitted && f.codiceDirezione.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="f.codiceDirezione.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
    </div>
    <!--close row-->
    <ng-template #popoverContentCup>
      <span>Codice unico progetto</span>
    </ng-template>
    <ng-template #popoverContentCig>
      <span>Codice Identificativo Gara</span>
    </ng-template>
    <ng-template #popoverContentRup>
      <span>Responsabile unico del procedimento / Referente</span>
    </ng-template>
    <div class="row">
      <div class="form-group col-sm-6">
        <label for="cup" class="">
          <h5 class="">
            <a
              type="button"
              class="help"
              [popover]="popoverContentCup"
              placement="top"
              triggers="mouseenter:mouseleave"
            >
              <i
                class="far fa-question-circle text-0083e0 help"
                style="font-size: 14px"
              >
              </i>
            </a>
            &nbsp;Cup:
          </h5>
        </label>
        <input
          [formGroup]="myGroup"
          maxlength="15"
          formControlName="cup"
          type="text"
          [ngClass]="{
            'form-control': true,
            'is-invalid': submitted && f.cup.errors
          }"
          placeholder="Inserisci il cup"
        />
        <div
          *ngIf="submitted && f.cup.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="f.cup.errors.required">Campo obbligatorio</div>
          <div *ngIf="f.cup.errors.maxlength || f.cup.errors.minlength">
            il valore deve essere di 15 caratteri
          </div>
        </div>
      </div>
      <div class="form-group col-sm-6">
        <label for="" class="">
          <h5 class="">
            <a
              type="button"
              class="help"
              [popover]="popoverContentCig"
              placement="top"
              triggers="mouseenter:mouseleave"
            >
              <i
                class="far fa-question-circle text-0083e0 help"
                style="font-size: 14px"
              >
              </i> </a
            >&nbsp;Cig:
          </h5>
        </label>

        <input
          [formGroup]="myGroup"
          maxlength="10"
          formControlName="cig"
          type="text"
          [ngClass]="{
            'form-control': true,
            'is-invalid': submitted && f.cig.errors
          }"
          placeholder="Inserisci il cig"
        />
        <div
          *ngIf="submitted && f.cig.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="f.cig.errors.required">Campo obbligatorio</div>
          <div *ngIf="f.cig.errors.maxlength || f.cig.errors.minlength">
            il valore deve essere di 10 caratteri
          </div>
        </div>
      </div>
    </div>
    <!--close row-->
    <ng-template #popoverContentBdu>
      <span> Banca Dati Unitaria</span>
    </ng-template>

    <div [hidden]="isRupHidden" class="row">
      <div class="form-group col-md-12 col-sm-12">
        <div class="d-flex justify-content-between">
          <label for="rupSelect">
            <h5>
              <a
                type="button"
                class="help"
                [popover]="popoverContentRup"
                placement="top"
                triggers="mouseenter:mouseleave"
              >
                <i
                  class="far fa-question-circle text-0083e0 help"
                  style="font-size: 14px"
                >
                </i> </a
              >&nbsp;Rup/Referente:
            </h5>
          </label>
          <div class="text-center " style="width:57.5px">
            <a class="bottone-inserisci" (click)="openModalRup($event)"><i class="fas fa-plus"></i></a>
          </div>
        </div>
        <div [ngClass]="{ 'is-invalid': submitted && f.rupSelect.errors }">
          <ng-select
            [items]="soggettiRespArray"
            bindValue="id"
            bindLabel="label"
            placeholder="Seleziona rup "
            (change)="setRup($event)"
            formControlName="rupSelect"
          >
          </ng-select>
        </div>
      </div>

      <div class="col-lg-12 mt-5" *ngIf="isIntervento || !esistonoInterventi">
        <hr />
      </div>
    </div>
    <!--close row-->


<!--subinterventi-->
<div class="row">
  <div class="col-lg-12 mt-4" *ngIf="isIntervento || !esistonoInterventi">
    <div class="row justify-content-between">
      <div class="col-lg-6 col-sm-6 col-6 mt-3">
        <h5 for="" class="">Sub-Interventi</h5>
      </div>      
    </div>
  </div>

  <div
    class="col-lg-12 mt-3 table-responsive-lg"
    *ngIf="isIntervento || !esistonoInterventi"
  >
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">CIG</th>
          <th scope="col">Descrizione</th>
          
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngIf="
            currentProject &&
            subInterventi?.length>0
          "
        >
          <tr *ngFor="let item of subInterventi">
            <td>{{ item.cig }}</td>
            <td>{{ item.descrizione }}</td>            
          </tr>
        </ng-container>
        <tr
          *ngIf="
            !(
              currentProject &&
              subInterventi?.length>0
            )
          "
        >
          <td colspan="4">Nessun sub-intervento selezionato</td>
        </tr>
      </tbody>
    </table>    
  </div>
</div>





    <!--banche dati esterne, visibili solo se senza interventi figli-->
    <div class="row">
      <div class="col-lg-12 mt-4" *ngIf="isIntervento || !esistonoInterventi">
        <div class="row justify-content-between">
          <div class="col-lg-6 col-sm-6 col-6 mt-3">
            <h5 for="" class="">Banche dati esterne</h5>
          </div>
          <div
            class="col-lg-1 col-sm2 col-3 text-center mt-3"
            [hidden]="readOnlyForm || validato"
          >
            <a
              class="bottone-inserisci pointer"
              (click)="openModalBancheDatiEsterne($event)"
              ><i class="fas fa-plus"></i
            ></a>
          </div>
        </div>
      </div>

      <div
        class="col-lg-12 mt-3 table-responsive-lg"
        *ngIf="isIntervento || !esistonoInterventi"
      >
        <table class="table table-hover">
          <thead>
            <tr>
              <th scope="col">descrizione</th>

              <th
                scope="col"
                class="casella-icona-tx"
                [hidden]="readOnlyForm || validato"
              >
                elimina
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container
              *ngIf="
                currentProject &&
                currentProject.bancheDatiEsterne &&
                currentProject.bancheDatiEsterne.length &&
                currentProject.bancheDatiEsterne.length > 0
              "
            >
              <tr *ngFor="let item of currentProject.bancheDatiEsterne">
                <td>{{ item.titolo }}</td>
                <td [hidden]="readOnlyForm || validato">
                  <a
                    (click)="removeBancaDatiEsterna(item)"
                    href="javascript:;;"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="elimina"
                    ><i class="far fa-trash-alt casella-icona-tx"></i>
                  </a>
                </td>
              </tr>
            </ng-container>
            <tr
              *ngIf="
                !(
                  currentProject &&
                  currentProject.bancheDatiEsterne &&
                  currentProject.bancheDatiEsterne.length &&
                  currentProject.bancheDatiEsterne.length > 0
                )
              "
            >
              <td colspan="4">Nessuna banca dati esterna selezionato</td>
            </tr>
          </tbody>
        </table>
        <div
          *ngIf="submitted && bancheDatiEsterneIsError"
          class="invalidFieldMessage"
        >
          <div
            *ngIf="bancheDatiEsterneIsError"
            class="invalid-feedback"
            style="display: block"
          >
            Selezionare almeno una banca dati esterna
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12 mt-5" *ngIf="isIntervento || !esistonoInterventi">
        <hr />
      </div>
    </div>
    <!--close row-->

    <div class="row" *ngIf="false && isIntervento">
      <div
        class="form-group col-sm-6"
        [ngClass]="{
          'is-invalid': submitted && f.presenteInBdu.errors
        }"
      >
        <label for="" class="">
          <h5 class="">
            <a
              type="button"
              class="help"
              [popover]="popoverContentBdu"
              placement="top"
              triggers="mouseenter:mouseleave"
            >
              <i
                class="far fa-question-circle text-0083e0 help"
                style="font-size: 14px"
              >
              </i>
            </a>
            &nbsp;Presente in BDU:
          </h5>
        </label>
        <div>
          <div class="form-check form-check-inline">
            <input
              [formGroup]="myGroup"
              id="presenteInBdu1"
              formControlName="presenteInBdu"
              type="radio"
              value="1"
              class="form-check-input"
              [ngClass]="{
                'is-invalid': submitted && f.presenteInBdu.errors
              }"
            />

            <label for="presenteInBdu1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input
              [formGroup]="myGroup"
              id="presenteInBdu2"
              formControlName="presenteInBdu"
              type="radio"
              value="0"
              class="form-check-input"
              [ngClass]="{
                'is-invalid': submitted && f.presenteInBdu.errors
              }"
            />
            <label for="presenteInBdu2" class="form-check-label">No</label>
          </div>
        </div>
      </div>
    </div>
    <!--close row-->
    <ng-template #popoverContentSal>
      <span>Permetti sal senza validazione</span>
    </ng-template>
    <!--****-->
    <!--flag abilitazione sal anche senza validazione-->
    <div class="row" *ngIf="!esistonoInterventi">
      <div
        class="form-group col-sm-6"
        [ngClass]="{
          'is-invalid': submitted && f.allowSalWithoutValidation.errors
        }"
      >
        <label for="" class="">
          <h5 class="">
            <a
              type="button"
              class="help"
              [popover]="popoverContentSal"
              placement="top"
              triggers="mouseenter:mouseleave"
            >
              <i
                class="far fa-question-circle text-0083e0 help"
                style="font-size: 14px"
              >
              </i>
            </a>
            &nbsp;Permetti SAL senza validazione:
          </h5>
        </label>
        <div>
          <div class="form-check form-check-inline">
            <input
              [formGroup]="myGroup"
              id="allowSalWithoutValidation1"
              formControlName="allowSalWithoutValidation"
              type="radio"
              value="1"
              class="form-check-input"
              [ngClass]="{
                'is-invalid': submitted && f.allowSalWithoutValidation.errors
              }"
            />

            <label for="allowSalWithoutValidation1" class="form-check-label"
              >Si</label
            >
          </div>
          <div class="form-check form-check-inline">
            <input
              [formGroup]="myGroup"
              id="allowSalWithoutValidation2"
              formControlName="allowSalWithoutValidation"
              type="radio"
              value="0"
              class="form-check-input"
              [ngClass]="{
                'is-invalid': submitted && f.allowSalWithoutValidation.errors
              }"
            />
            <label for="allowSalWithoutValidation2" class="form-check-label"
              >No</label
            >
          </div>
        </div>
      </div>
    </div>
    <!--close row-->
    <!--fine flag abilitazione sal anche senza validazione-->
  </div>
  <!--inizio dati statistici-->
  <div class="col-lg-12 mt-5">
    <hr />
  </div>
  <div class="col-lg-12 mt-4">
    <accordion [isAnimated]="true">
      <accordion-group
        heading="Dati Statistici"
        #accordionDatiStatistici
        [isOpen]="isDatiStaticiOpen"
      >
        <accordion [isAnimated]="true" [hidden]="hideTipologia">
          <accordion-group heading="Motivazioni">
            <!--inizio motivazioni-->
            <div class="col-lg-12 mt-4">
              <div class="row justify-content-between">
                <div class="col-lg-6 col-sm-6 col-6 mt-3">
                  <h5
                    for=""
                    class=""
                    [ngClass]="{
                      mandatory: !currentProject || !currentProject.id
                    }"
                  >
                    Motivazioni
                  </h5>
                </div>
                <div
                  class="col-lg-1 col-sm2 col-3 text-center mt-3"
                  [hidden]="readOnlyForm || validato"
                >
                  <a
                    class="bottone-inserisci"
                    (click)="openModalMotivazioni()"
                    href="javascript:;;"
                    ><i class="fas fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-3 table-responsive-lg">
              <table class="table table-hover table-colore">
                <thead>
                  <tr>
                    <th scope="col">Motivazione</th>
                    <th
                      scope="col"
                      class="casella-icona-tx"
                      [hidden]="readOnlyForm || validato"
                    >
                      elimina
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngIf="
                      currentProject &&
                      currentProject['motivazioni'] &&
                      currentProject['motivazioni'].length &&
                      currentProject['motivazioni'].length > 0
                    "
                  >
                    <tr *ngFor="let item of currentProject['motivazioni']">
                      <td>{{ item.motivazione }}</td>
                      <td [hidden]="readOnlyForm || validato">
                        <a
                          (click)="removeMotivazione(item)"
                          href="javascript:;;"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="elimina"
                          ><i class="far fa-trash-alt casella-icona-tx"></i>
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                  <tr
                    *ngIf="
                      !(
                        currentProject &&
                        currentProject['motivazioni'] &&
                        currentProject['motivazioni'].length &&
                        currentProject['motivazioni'].length > 0
                      )
                    "
                  >
                    <td colspan="2">Nessuna motivazione selezionata</td>
                  </tr>
                </tbody>
              </table>
              <div
                *ngIf="submitted && motivazioniIsError"
                class="invalidFieldMessage"
              >
                <div
                  *ngIf="motivazioniIsError"
                  class="invalid-feedback"
                  style="display: block"
                >
                  Selezionare almeno una motivazione
                </div>
              </div>
            </div>
            <!--fine classificazioni-->
          </accordion-group>
        </accordion>

        <accordion [isAnimated]="true" [hidden]="hideTipologia">
          <accordion-group heading="Priorità">
            <div class="form-group row">
              <div class="col-sm-12">
                <div class="row justify-content-between">
                  <div class="col-lg-6 col-sm-6 col-6 mt-3">
                    <label for="prioritaMotivazione">
                      <h5
                        class=""
                        [ngClass]="{
                          mandatory: !currentProject || !currentProject.id
                        }"
                      >
                        Priorità:
                      </h5>
                    </label>
                  </div>
                  <div class="col-lg-1 col-sm2 col-3 text-center mt-3">
                    <a
                      class="bottone-inserisci"
                      (click)="openModalPriorita($event)"
                      ><i class="fas fa-plus"></i
                    ></a>
                  </div>
                </div>
                <div
                  [ngClass]="{
                    'is-invalid': submitted && f.prioritaMotivazione.errors
                  }"
                ></div>
                <div class="overflow-box">
                  <ng-select
                    [items]="prioritaMotivazioniList"
                    bindValue="id"
                    bindLabel="label"
                    placeholder="Seleziona priorità"
                    (change)="setPrioritaMotivazione($event)"
                    formControlName="prioritaMotivazione"
                  >
                  </ng-select>
                </div>
                <div
                  *ngIf="submitted && f.prioritaMotivazione.errors"
                  class="invalid-feedback"
                  style="display: block"
                >
                  <div *ngIf="f.prioritaMotivazione.errors.required">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
            </div>
          </accordion-group>
        </accordion>

        <accordion [isAnimated]="true" [hidden]="hideTipologia">
          <accordion-group heading="Dati per analisi">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="importoBaseAsta">
                  <h5 class="">Importo a base d'asta:</h5>
                </label>
                <div
                  [ngClass]="{
                    'is-invalid': submitted && f.importoBaseAsta.errors
                  }"
                >
                  <input
                    [formGroup]="myGroup"
                    formControlName="importoBaseAsta"
                    maxlength="255"
                    type="text"
                    class="form-control"
                    placeholder="Inserisci l'importo a base d'asta"
                    [ngClass]="{
                      'is-invalid':
                        submitted && myGroup.controls.importoAggiudicato.errors
                    }"
                    [imask]="mask"
                    [unmask]="true"
                  />
                </div>
                <div
                  *ngIf="submitted && f.importoBaseAsta.errors"
                  class="invalid-feedback"
                  style="display: block"
                >
                  <div *ngIf="f.importoBaseAsta.errors.required">
                    Campo obbligatorio
                  </div>
                </div>
              </div>

              <div class="form-group col-md-6">
                <label for="importoAggiudicato">
                  <h5 class="">Importo aggiudicato:</h5>
                </label>
                <div
                  [ngClass]="{
                    'is-invalid': submitted && f.importoAggiudicato.errors
                  }"
                >
                  <input
                    [formGroup]="myGroup"
                    formControlName="importoAggiudicato"
                    maxlength="255"
                    type="text"
                    class="form-control"
                    placeholder="Inserisci l'importo aggiudicato"
                    [ngClass]="{
                      'is-invalid':
                        submitted && myGroup.controls.importoAggiudicato.errors
                    }"
                    [imask]="mask"
                    [unmask]="true"
                  />
                </div>
                <div
                  *ngIf="submitted && f.importoAggiudicato.errors"
                  class="invalid-feedback"
                  style="display: block"
                >
                  <div *ngIf="f.importoAggiudicato.errors.required">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
            </div>

            <ng-template #popoverContentTipologiaIntervento>
              <span>Selezionare una tipologia di intervento</span>
            </ng-template>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="parametriTecnici">
                  <h5 class=" ">Parametri tecnici previsti:</h5>
                </label>
                <div
                  [ngClass]="{
                    'is-invalid': submitted && f.parametriTecnici.errors
                  }"
                  class="input-group"
                >
                  <div class="input-group-prepend">
                    <span
                      *ngIf="getUnitaDiMisura() && getUnitaDiMisura() != ''"
                      class="input-group-text"
                      id="inputGroupPrepend"
                      >{{ getUnitaDiMisura() }}</span
                    >
                    <a
                      *ngIf="!getUnitaDiMisura() || getUnitaDiMisura() == ''"
                      class="input-group-text help"
                      id="inputGroupPrepend"
                      type="button"
                      [popover]="popoverContentTipologiaIntervento"
                      placement="top"
                      triggers="mouseenter:mouseleave"
                    >
                      <i
                        class="far fa-question-circle text-0083e0 help"
                        style="font-size: 14px"
                      >
                      </i>
                    </a>
                  </div>
                  <input
                    type="text"
                    [imask]="maskValue"
                    [unmask]="true"
                    [formGroup]="myGroup"
                    formControlName="parametriTecnici"
                    class="form-control"
                  />

                  <!--<input type="text" class="form-control col-2" readonly placeholder="{{getUnitaDiMisura()}}">-->

                  <div
                    *ngIf="submitted && f.parametriTecnici.errors"
                    class="invalid-feedback"
                    style="display: block"
                  >
                    <div *ngIf="f.parametriTecnici.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="form-group col-md-6"
                [hidden]="
                  !currentProject['tipo'] || currentProject['tipo'] == 'skilift'
                "
              >
                <label for="tipologiaStatistici">
                  <h5 class=" ">Tipologia:</h5>
                </label>
                <div
                  [ngClass]="{
                    'is-invalid': submitted && f.tipologiaStatistici.errors
                  }"
                >
                  <ng-select
                    [items]="tipologieStatistici"
                    bindValue="k"
                    bindLabel="v"
                    placeholder="Seleziona tipologia"
                    formControlName="tipologiaStatistici"
                    [formGroup]="myGroup"
                    (change)="setTipologiaStatistici($event)"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.v }}">{{ trimText(item.v, 50) }}</div>
                    </ng-template>
                  </ng-select>
                </div>
                <div
                  *ngIf="submitted && f.tipologiaStatistici.errors"
                  class="invalid-feedback"
                  style="display: block"
                >
                  <div *ngIf="f.tipologiaStatistici.errors.required">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="parametriTecniciEffettivi">
                  <h5 class=" ">Parametri tecnici effettivi:</h5>
                </label>
                <div
                  [ngClass]="{
                    'is-invalid':
                      submitted && f.parametriTecniciEffettivi.errors
                  }"
                  class="input-group"
                >
                  <div class="input-group-prepend">
                    <span
                      *ngIf="getUnitaDiMisura() && getUnitaDiMisura() != ''"
                      class="input-group-text"
                      id="inputGroupPrepend"
                      >{{ getUnitaDiMisura() }}</span
                    >
                    <a
                      *ngIf="!getUnitaDiMisura() || getUnitaDiMisura() == ''"
                      class="input-group-text help"
                      id="inputGroupPrepend"
                      type="button"
                      [popover]="popoverContentTipologiaIntervento"
                      placement="top"
                      triggers="mouseenter:mouseleave"
                    >
                      <i
                        class="far fa-question-circle text-0083e0 help"
                        style="font-size: 14px"
                      >
                      </i>
                    </a>
                  </div>
                  <input
                    type="text"
                    [imask]="maskValue"
                    [unmask]="true"
                    [formGroup]="myGroup"
                    formControlName="parametriTecniciEffettivi"
                    class="form-control"
                  />

                  <div
                    *ngIf="submitted && f.parametriTecniciEffettivi.errors"
                    class="invalid-feedback"
                    style="display: block"
                  >
                    <div *ngIf="f.parametriTecniciEffettivi.errors.required">
                      Campo obbligatorio
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div
                class="form-group col-md-4"
                [hidden]="
                  !currentProject['tipo'] ||
                  (currentProject['tipo'] != 'road' &&
                    currentProject['tipo'] != 'rail' &&
                    currentProject['tipo'] != 'bike' &&
                    currentProject['tipo'] != 'port')
                "
              >
                <label for="statisticiExtraField1">
                  <h5 class=" ">
                    {{ getLabelStatisticiExtraField1() }}&thinsp;:
                  </h5>
                </label>
                <div
                  [ngClass]="{
                    'is-invalid': submitted && f.statisticiExtraField1.errors
                  }"
                >
                  <ng-select
                    *ngIf="
                      currentProject['tipo'] &&
                      (currentProject['tipo'] == 'road' ||
                        currentProject['tipo'] == 'rail' ||
                        currentProject['tipo'] == 'bike')
                    "
                    [items]="[
                      { k: 'Si', v: 'Si' },
                      { k: 'No', v: 'No' }
                    ]"
                    bindValue="k"
                    bindLabel="v"
                    placeholder="Seleziona valore"
                    formControlName="statisticiExtraField1"
                    [formGroup]="myGroup"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.v }}">{{ trimText(item.v, 50) }}</div>
                    </ng-template>
                  </ng-select>
                  <input
                    *ngIf="
                      currentProject['tipo'] && currentProject['tipo'] == 'port'
                    "
                    type="text"
                    [imask]="maskValueNumber"
                    [unmask]="true"
                    [formGroup]="myGroup"
                    formControlName="statisticiExtraField1"
                    class="form-control"
                  />
                </div>
                <div
                  *ngIf="submitted && f.statisticiExtraField1.errors"
                  class="invalid-feedback"
                  style="display: block"
                >
                  <div *ngIf="f.statisticiExtraField1.errors.required">
                    Campo obbligatorio
                  </div>
                </div>
              </div>

              <div
                class="form-group col-md-4"
                [hidden]="
                  !currentProject['tipo'] ||
                  (currentProject['tipo'] != 'road' &&
                    currentProject['tipo'] != 'rail' &&
                    currentProject['tipo'] != 'bike')
                "
              >
                <label for="statisticiExtraField2">
                  <h5 class=" ">{{ getLabelStatisticiExtraField2() }}:</h5>
                </label>
                <div
                  [ngClass]="{
                    'is-invalid': submitted && f.statisticiExtraField2.errors
                  }"
                >
                  <ng-select
                    [items]="itemsStatisticiExtraField2"
                    bindValue="k"
                    bindLabel="v"
                    placeholder="Seleziona valore"
                    formControlName="statisticiExtraField2"
                    [formGroup]="myGroup"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.v }} ">
                        {{ trimText(item.v, 50) }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div
                  *ngIf="submitted && f.statisticiExtraField2.errors"
                  class="invalid-feedback"
                  style="display: block"
                >
                  <div *ngIf="f.statisticiExtraField2.errors.required">
                    Campo obbligatorio
                  </div>
                </div>
              </div>

              <div
                class="form-group col-md-4"
                [hidden]="
                  !currentProject['tipo'] || currentProject['tipo'] != 'rail'
                "
              >
                <label for="statisticiExtraField3">
                  <h5 class=" ">Elettrificazione:</h5>
                </label>
                <div
                  [ngClass]="{
                    'is-invalid': submitted && f.statisticiExtraField3.errors
                  }"
                >
                  <ng-select
                    [items]="[
                      { k: 'Si', v: 'Si' },
                      { k: 'No', v: 'No' }
                    ]"
                    bindValue="k"
                    bindLabel="v"
                    placeholder="Seleziona valore"
                    formControlName="statisticiExtraField3"
                    [formGroup]="myGroup"
                  >
                    <ng-template ng-option-tmp let-item="item">
                      <div title="{{ item.v }} ">
                        {{ trimText(item.v, 50) }}
                      </div>
                    </ng-template>
                  </ng-select>
                </div>
                <div
                  *ngIf="submitted && f.statisticiExtraField3.errors"
                  class="invalid-feedback"
                  style="display: block"
                >
                  <div *ngIf="f.statisticiExtraField3.errors.required">
                    Campo obbligatorio
                  </div>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="importoBaseAstaUnitario">
                  <h5 class=" ">Importo a base d'asta unitario:</h5>
                </label>
                <div>
                  <span>{{ getImportoBaseAstaUnitario() }}</span>
                </div>
              </div>
              <div class="form-group col-md-6">
                <label for="importoAggiudicatoUnitario">
                  <h5 class=" ">Importo aggiudicato unitario:</h5>
                </label>
                <div>
                  <span>{{ getImportoAggiudicatoUnitario() }}</span>
                </div>
              </div>
            </div>
          </accordion-group>
        </accordion>

        <accordion [isAnimated]="true">
          <accordion-group
            heading="Classificazioni"
            [isOpen]="isClassificazioniOpen"
          >
            <!--inizio classificazioni-->
            <div class="col-lg-12 mt-4">
              <div class="row justify-content-between">
                <div class="col-lg-6 col-sm-6 col-6 mt-3">
                  <h5 for=" " class=" ">Classificazioni</h5>
                </div>
                <div
                  class="col-lg-1 col-sm2 col-3 text-center mt-3"
                  [hidden]="readOnlyForm || validato"
                >
                  <a
                    class="bottone-inserisci"
                    (click)="openModalClassificazioni()"
                    href="javascript:;; "
                    ><i class="fas fa-plus"></i
                  ></a>
                </div>
              </div>
            </div>
            <div class="col-lg-12 mt-3 table-responsive-lg">
              <table class="table table-hover table-colore">
                <thead>
                  <tr>
                    <th scope="col ">codice</th>
                    <th scope="col ">tipo</th>
                    <th scope="col " class="casella-descrizione-tx">
                      descrizione
                    </th>
                    <th
                      scope="col "
                      class="casella-icona-tx"
                      [hidden]="readOnlyForm || validato"
                    >
                      elimina
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngIf="
                      currentProject &&
                      currentProject['classificazioni'] &&
                      currentProject['classificazioni'].length &&
                      currentProject['classificazioni'].length > 0
                    "
                  >
                    <tr *ngFor="let item of currentProject.classificazioni">
                      <td>{{ item.codice }}</td>
                      <td>
                        {{
                          item.tipoClassificazione.tipo
                            ? item.tipoClassificazione.tipo
                            : item.tipoClassificazione
                        }}
                      </td>
                      <td>{{ item.descrizione }}</td>
                      <td [hidden]="readOnlyForm || validato">
                        <a
                          (click)="removeClassificazione(item)"
                          href="javascript:;;"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="elimina"
                          ><i class="far fa-trash-alt casella-icona-tx"></i>
                        </a>
                      </td>
                    </tr>
                  </ng-container>
                  <tr
                    *ngIf="
                      !(
                        currentProject &&
                        currentProject.classificazioni &&
                        currentProject.classificazioni.length &&
                        currentProject.classificazioni.length > 0
                      )
                    "
                  >
                    <td colspan="4">Nessuna classificazione selezionata</td>
                  </tr>
                </tbody>
              </table>
              <div
                *ngIf="submitted && classificazioniIsError"
                class="invalidFieldMessage"
              >
                <div
                  *ngIf="classificazioniIsError"
                  class="invalid-feedback"
                  style="display: block"
                >
                  Selezionare almeno una classificazione
                </div>
              </div>
            </div>
            <!--fine classificazioni-->
          </accordion-group>
        </accordion>
      </accordion-group>
    </accordion>
  </div>

  <!--fine dati statistici-->

  <div class="col-lg-12 mt-5">
    <hr />
  </div>

  <div class="col-lg-12 mt-4">
    <div class="row justify-content-between">
      <div class="col-lg-6 col-sm-6 col-6 mt-3">
        <h5 for="" class="mandatory">Aree gestionali direzione e/o esterne</h5>
      </div>
      <div
        class="col-lg-1 col-sm-2 col-3 text-center mt-3"
        [hidden]="readOnlyForm || validato"
      >
        <a
          href="javascript:;;"
          class="bottone-inserisci"
          (click)="openModalAT()"
          ><i class="fas fa-plus"></i
        ></a>
      </div>
    </div>
  </div>

  <div class="col-lg-12 mt-3 table-responsive-lg">
    <table class="table table-hover table-colore">
      <thead class="">
        <th class="casella-descrizione-tx">descrizione</th>
        <th>codice ipa</th>
        <th>struttura</th>
        <th class="casella-icona-tx" [hidden]="readOnlyForm || validato">
          elimina
        </th>
      </thead>
      <tbody>
        <ng-container
          *ngIf="
            currentProject &&
            currentProject['areeTematiche'] &&
            currentProject['areeTematiche'].length &&
            currentProject['areeTematiche'].length > 0
          "
        >
          <tr *ngFor="let item of currentProject.areeTematiche">
            <td>
              {{ item.disabled ? "(disattiva) " : "" }}{{ item.descrizione }}
            </td>
            <td>{{ item.codiceIpa }}</td>
            <td>{{ item.struttura }}</td>
            <td [hidden]="readOnlyForm || validato">
              <a
                (click)="removeAreaTematica(item)"
                href="javascript:;;"
                data-toggle="tooltip"
                data-placement="top"
                title="elimina"
                ><i class="far fa-trash-alt casella-icona-tx"></i>
              </a>
            </td>
          </tr>
        </ng-container>
        <tr
          *ngIf="
            !(
              currentProject &&
              currentProject['areeTematiche'] &&
              currentProject['areeTematiche'].length &&
              currentProject['areeTematiche'].length > 0
            )
          "
        >
          <td colspan="4">Nessuna area direzione selezionata</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="submitted && areeTematicheIsError" class="invalidFieldMessage">
      <div
        *ngIf="areeTematicheIsError"
        class="invalid-feedback"
        style="display: block"
      >
        Selezionare almeno un'area direzione
      </div>
    </div>
  </div>

  <div class="col-lg-12 mt-5">
    <hr />
  </div>
  <div class="col-lg-12 mt-4">
    <div class="row justify-content-between">
      <div class="col-6 mt-3">
        <h5 for="" class="">Soggetti giuridici</h5>
      </div>
      <div
        class="col-lg-1 col-sm2 col-3 text-center mt-3"
        [hidden]="readOnlyForm || validato"
      >
        <a
          class="bottone-inserisci pointer"
          (click)="openModalSoggettiGiuridici($event)"
          ><i class="fas fa-plus"></i
        ></a>
      </div>
    </div>
  </div>
  <div class="col-lg-12 mt-3 table-responsive-lg">
    <table class="table table-hover table-colore">
      <thead class="">
        <th>sigla prov.</th>
        <th>codice</th>
        <th scope="col" class="casella-descrizione-tx">denominazione</th>
        <th>ruolo</th>
        <th
          scope="col"
          class="casella-icona-tx"
          [hidden]="readOnlyForm || validato"
        >
          elimina
        </th>
      </thead>
      <tbody>
        <ng-container
          *ngIf="
            currentProject &&
            currentProject.soggettiGiuridici &&
            currentProject.soggettiGiuridici.length &&
            currentProject.soggettiGiuridici.length > 0
          "
        >
          <tr *ngFor="let item of currentProject.soggettiGiuridici">
            <td>{{ item.siglaProvincia }}</td>
            <td>{{ item.codiceIdentificativo }}</td>
            <td>{{ item.denominazione }}</td>
            <td>{{ item.ruolo }}</td>
            <td [hidden]="readOnlyForm || validato">
              <a
                (click)="removeSoggettoGiuridico(item)"
                href="javascript:;;"
                data-toggle="tooltip"
                data-placement="top"
                title="elimina"
                ><i class="far fa-trash-alt casella-icona-tx"></i>
              </a>
            </td>
          </tr>
        </ng-container>
        <tr
          *ngIf="
            !(
              currentProject &&
              currentProject.soggettiGiuridici &&
              currentProject.soggettiGiuridici.length &&
              currentProject.soggettiGiuridici.length > 0
            )
          "
        >
          <td colspan="5">Nessun soggetto giuridico selezionato</td>
        </tr>
      </tbody>
    </table>
    <div
      *ngIf="submitted && soggettiGiuridiciIsError"
      class="invalidFieldMessage"
    >
      <div
        *ngIf="soggettiGiuridiciIsError"
        class="invalid-feedback"
        style="display: block"
      >
        Selezionare almeno un soggetto giuridico
      </div>
    </div>
  </div>

  <div class="col-lg-12 mt-5">
    <hr />
  </div>

  <div class="col-lg-12 mt-4">
    <div class="row justify-content-between">
      <div class="col-lg-6 col-sm-6 col-6 mt-3">
        <h5 for="" class="">Temi prioritari</h5>
      </div>
      <div
        class="col-lg-1 col-sm2 col-3 text-center mt-3"
        [hidden]="readOnlyForm || validato"
      >
        <a
          class="bottone-inserisci pointer"
          (click)="openModalTemiPrioritari($event)"
          ><i class="fas fa-plus"></i
        ></a>
      </div>
    </div>
  </div>

  <div class="col-lg-12 mt-3 table-responsive-lg">
    <table class="table table-hover">
      <thead>
        <tr>
          <th scope="col">descrizione</th>

          <th
            scope="col"
            class="casella-icona-tx"
            [hidden]="readOnlyForm || validato"
          >
            elimina
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container
          *ngIf="
            currentProject &&
            currentProject.temiPrioritari &&
            currentProject.temiPrioritari.length &&
            currentProject.areeTematiche.length > 0
          "
        >
          <tr *ngFor="let item of currentProject.temiPrioritari">
            <td>{{ item.descrizione }}</td>
            <td [hidden]="readOnlyForm || validato">
              <a
                (click)="removeTemaPrioritario(item)"
                href="javascript:;;"
                data-toggle="tooltip"
                data-placement="top"
                title="elimina"
                ><i class="far fa-trash-alt casella-icona-tx"></i>
              </a>
            </td>
          </tr>
        </ng-container>
        <tr
          *ngIf="
            !(
              currentProject &&
              currentProject.temiPrioritari &&
              currentProject.temiPrioritari.length &&
              currentProject.areeTematiche.length > 0
            )
          "
        >
          <td colspan="4">Nessun tema prioritario selezionato</td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="submitted && temiPrioritariIsError" class="invalidFieldMessage">
      <div
        *ngIf="temiPrioritariIsError"
        class="invalid-feedback"
        style="display: block"
      >
        Selezionare almeno un tema prioritario
      </div>
    </div>
  </div>

  <div class="col-lg-12 mt-5">
    <hr />
  </div>

  <div class="col-lg-12 mt-5 mb-5">
    <div class="form-group row">
      <div class="col-sm-12 mb-3">
        <label for="codice" class="">
          <h5>Note responsabile:</h5>
        </label>
        <textarea
          [formGroup]="myGroup"
          formControlName="noteResponsabile"
          type="text"
          [ngClass]="{
            'form-control': true,
            'is-invalid': submitted && f.noteResponsabile.errors
          }"
          placeholder="Inserisci eventuali note"
          rows="3"
        ></textarea>
        <div
          *ngIf="submitted && f.noteResponsabile.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="f.noteResponsabile.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
    </div>
  </div>
</form>

<ng-template #templateModalImporti>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Inserisci importo</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="modalRefTemplateImporti.hide()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="editFormImporti" (ngSubmit)="onSubmitImporti()">
      <input type="hidden" [formGroup]="editFormImporti" formControlName="id" />
      <div class="form-group">
        <label for="anno" class="mandatory">Anno:</label>
        <input
          [formGroup]="editFormImporti"
          formControlName="anno"
          maxlength="4"
          minlength="4"
          type="text"
          mask="0000"
          [showMaskTyped]="false"
          class="form-control"
          placeholder="Inserisci l'anno"
          [ngClass]="{
            'form-control': true,
            'is-invalid':
              submittedImporti && editFormImporti.controls.anno.errors
          }"
        />
        <div
          *ngIf="submittedImporti && editFormImporti.controls.anno.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="editFormImporti.controls.anno.errors.required">
            Campo obbligatorio
          </div>
          <div *ngIf="editFormImporti.controls.anno.errors.minlength">
            Campo deve essere di 4 cifre
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="importo" class="mandatory">Importo:</label>
        <input
          [formGroup]="editFormImporti"
          formControlName="importo"
          type="text"
          [showMaskTyped]="false"
          mask="separator.2"
          thousandSeparator="."
          suffix=" €"
          class="form-control"
          placeholder="Inserisci l'importo"
          [ngClass]="{
            'form-control': true,
            'is-invalid':
              submittedImporti && editFormImporti.controls.importo.errors
          }"
        />
        <div
          *ngIf="submittedImporti && editFormImporti.controls.importo.errors"
          class="invalid-feedback"
          style="display: block"
        >
          <div *ngIf="editFormImporti.controls.importo.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <button
        class="btn btn-lg btn-primary btn-block"
        type="submit"
        style="margin: 15px 0"
      >
        Inserisci
      </button>
    </form>
  </div>
</ng-template>

<ng-template #templateModalPriorita>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Aggiungi nuova priorità</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModalPriorita()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-lg-12">
      <form
        [formGroup]="prioritaModalForm"
        (ngSubmit)="onSubmitPrioritaModal()"
      >
        <input
          type="hidden"
          [formGroup]="prioritaModalForm"
          formControlName="id"
        />
        <div class="row">
          <div class="form-group col-lg-12">
            <h5>
              <label for="priorita" class="mandatory">Priorità:</label>
            </h5>
            <input
              [formGroup]="prioritaModalForm"
              formControlName="priorita"
              maxlength="255"
              type="text"
              class="form-control"
              placeholder="Inserisci priorità"
              [ngClass]="{
                'is-invalid':
                  submitted && hasError(prioritaModalForm, 'priorita')
              }"
            />
            <div
              *ngIf="submitted && hasError(prioritaModalForm, 'priorita')"
              class="invalid-feedback"
              style="display: block"
            >
              Campo obbligatorio
            </div>
          </div>
          <div class="form-group col-lg-12">
            <h5>
              <label for="motivazione" class="mandatory">Motivazione:</label>
            </h5>
            <input
              [formGroup]="prioritaModalForm"
              formControlName="motivazione"
              maxlength="255"
              type="text"
              class="form-control"
              placeholder="Inserisci la motivazione"
              [ngClass]="{
                'is-invalid':
                  submitted && hasError(prioritaModalForm, 'motivazione')
              }"
            />
            <div
              *ngIf="submitted && hasError(prioritaModalForm, 'motivazione')"
              class="invalid-feedback"
              style="display: block"
            >
              Campo obbligatorio
            </div>
          </div>
        </div>
        <button
          class="btn btn-lg btn-primary btn-block"
          type="submit"
          style="margin: 15px 0"
        >
          Aggiungi
        </button>
      </form>
    </div>
  </div>
</ng-template>

<!-- Modale insert RUP -->
<ng-template #templateModalRup>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Inserisci un nuovo soggetto responsabile</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
      (click)="closeModalRup()"
    >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="rupForm" class="col-lg-12" (ngSubmit)="onSubmitRupForm()">
      <input type="hidden" [formGroup]="rupForm" formControlName="id" />
      <div class="row">
        <div class="form-group col-lg-6">
          <h5><label for="cognome" class="mandatory">Cognome:</label></h5>
          <input [formGroup]="rupForm" formControlName="cognome" maxlength="255" type="text" value=""
          class="form-control" placeholder="Inserisci il cognome" [ngClass]="{
            'form-control': true,
            'is-invalid': submittedRup && r.cognome.errors
          }" />
        <div *ngIf="submittedRup && r.cognome.errors" class="invalid-feedback" style="display: block;">
          <div *ngIf="r.cognome.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
      <div class="form-group col-lg-6">
        <h5><label for="nome" class="mandatory">Nome:</label></h5>
        <input [formGroup]="rupForm" formControlName="nome" maxlength="255" type="text" value="" class="form-control"
        placeholder="Inserisci il nome" [ngClass]="{
          'form-control': true,
          'is-invalid': submittedRup && r.nome.errors
        }" />
        <div *ngIf="submittedRup && r.nome.errors" class="invalid-feedback" style="display: block;">
          <div *ngIf="r.nome.errors.required">
            Campo obbligatorio
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="row justify-content-between">
        
        <div class=" col-lg-6 col-sm-6 col-6 ">
          <h5><label for="email" class="mandatory">Email:</label></h5>
        </div>
        <div class="col-lg-1 col-sm-2 col-3 text-right">
          <a class="bottone-inserisci" (click)="addEmail();"><i class="fas fa-plus"
            title="clicca per aggiungere un'ulteriore email"></i></a>
          </div>
        </div>
        <div formArrayName="emails" *ngFor="let item of rupForm.get('emails').controls; let i = index;">
          <div class="input-group mb-3" [formGroupName]="i">
            
            <input [formGroup]="rupForm" formControlName="email" maxlength="255" type="text" value=""
            class="form-control" placeholder="Inserisci l'email"
            [ngClass]="{ 'form-control': true, 'is-invalid': submittedRup && rupForm.get('emails').controls[i].controls['email'].errors }" />
            
            <i *ngIf="rupForm.get('emails').controls.length!=1" class="far fa-trash-alt mx-2 pointer"
            style="padding-top: 10px; padding-bottom:10px;" title="elimina email" (click)="removeEmail(i);"></i>
            <div *ngIf="submittedRup && rupForm.get('emails').controls[i].controls['email'].errors" class="invalid-feedback"
            style="display: block;" style="margin-bottom:10px;">
            <div *ngIf="rupForm.get('emails').controls[i].controls['email'].errors.required">
              Campo obbligatorio
            </div>
            <div *ngIf="rupForm.get('emails').controls[i].controls['email'].errors.email">
              Valore non valido
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <h5><label for="tipologia" class="mandatory">Tipologia:</label></h5>
      <div [ngClass]="{ 'is-invalid': submittedRup && r.tipologia.errors }">
        <ng-select [formGroup]="rupForm" formControlName="tipologia" [items]="tipologie" bindValue="id"
        bindLabel="descrizione" placeholder="Seleziona la tipologia">
      </ng-select>
    </div>
    <div *ngIf="submittedRup && r.tipologia.errors" class="invalidFieldMessage" style="display: block;">
      <div *ngIf="r.tipologia.errors" class="invalid-feedback" style="display: block;">
        Campo obbligatorio
      </div>
    </div>
  </div>
  <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
    Aggiungi
    <img *ngIf="loading" class="pl-3 float-right"
    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
  </button>
</form>
</div>
</ng-template>

<ng-template #btnAdd>
  <button class="btn btn-primary mb-3" (click)="openModal($event)" href="javascript:;;">
      Aggiungi nuova <i class="fas fa-plus" aria-hidden="true"></i> 
  </button>
</ng-template>

<ng-template #modalFormAddBancheDatiEsterne>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Inserisci banche dati esterne</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
     (click)="closeModalBancheDatiEsterneForm()"
      >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  
  <div class="modal-body">
    <form [formGroup]="bancheDatiEsterneForm" (ngSubmit)="onSubmitBde()">
      <input type="hidden" [formGroup]="bancheDatiEsterneForm" formControlName="id" />
      <div class="row">
          
          <div class="form-group col-lg-12">
              <h5> <label for="titolo" class="mandatory">Titolo:</label></h5>
              <input [formGroup]="bancheDatiEsterneForm" formControlName="titolo" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la fase" [ngClass]="{
                'form-control': true,
                'is-invalid': submitBde && b.titolo.errors
                }" />
              <div *ngIf="submitBde && b.titolo.errors" class="invalid-feedback" style="display: block;">
                  <div *ngIf="b.titolo.errors.required">
                      Campo obbligatorio
                  </div>
              </div>
          </div>
          

      </div>            
      <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
      Aggiungi
  </button>
  </form>
  </div>
</ng-template>

<ng-template #modalTemplateMotivazioneForm>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Inserisci motivazione</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
     (click)="closeModalMotivazioniForm()"
      >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <form [formGroup]="addMotivazioneForm" (ngSubmit)="onSubmitMotivazione()">
      <input type="hidden" [formGroup]="addMotivazioneForm" formControlName="id"/>
      <div class="row">
          <div class="form-group col-lg-12">
              <h5> <label for="motivazione" class="mandatory">Motivazione:</label></h5>
              <input [formGroup]="addMotivazioneForm" formControlName="motivazione" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la fase" [ngClass]="{
                'form-control': true,
                'is-invalid': submitMotivazione && m.motivazione.errors
                }" />
              <div *ngIf="submitMotivazione && m.motivazione.errors" class="invalid-feedback" style="display: block;">
                  <div *ngIf="m.motivazione.errors.required">
                      Campo obbligatorio
                  </div>
              </div>
          </div>
      </div>            
      <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
      Aggiungi
  </button>
  </form>
  </div>
</ng-template>

<ng-template #modalTemplateClassificazione>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Inserisci classificazione</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
     (click)="closeModalClassificazione()"
      >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="classificazioneForm" (ngSubmit)="onSubmitClassificazione()" class="col-lg-12">
      <input type="hidden" [formGroup]="classificazioneForm" formControlName="id" />
      <div class="row">
          <div class="form-group col-lg-6">
              <h5><label for="codice" class="mandatory">Codice:</label></h5>
              <input [formGroup]="classificazioneForm" formControlName="codice" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci il codice" [ngClass]="{
                  'form-control': true,
                  'is-invalid': submitClassificazione && c.codice.errors
                  }" />
              <div *ngIf="submitClassificazione && c.codice.errors" class="invalid-feedback" style="display: block;">
                  <div *ngIf="c.codice.errors.required">
                      Campo obbligatorio
                  </div>
              </div>
          </div>
          <div class="form-group col-lg-6">
              <h5><label for="ordine" class="mandatory">Ordine:</label></h5>
              <input [formGroup]="classificazioneForm" formControlName="ordine" type="number" value="" class="form-control" placeholder="Inserisci l'ordine" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitClassificazione && c.ordine.errors
                    }" />
              <div *ngIf="submitClassificazione && c.ordine.errors" class="invalid-feedback" style="display: block;">
                  <div *ngIf="c.ordine.errors.required">
                      Campo obbligatorio
                  </div>
              </div>
          </div>
      </div>
      <div class="form-group">
          <h5><label for="idTipoClassificazione" class="mandatory">Tipo Classificazione:</label></h5>
          <div [ngClass]="{ 'is-invalid': submitClassificazione && c.idTipoClassificazione.errors }">
              <ng-select [formGroup]="classificazioneForm" formControlName="idTipoClassificazione" [items]="tipiClassificazione" bindValue="id" bindLabel="tipo" (change)="onChangeIdTipoClassificazione($event)" placeholder="Seleziona tipo classificazione">
              </ng-select>
          </div>
          <div *ngIf="submitClassificazione && c.idTipoClassificazione.errors" class="invalidFieldMessage" style="display: block;">
              <div *ngIf="c.idTipoClassificazione.errors" class="invalid-feedback" style="display: block;">
                  Campo obbligatorio
              </div>
          </div>
      </div>
      <div class="form-group">
          <h5><label for="descrizione" class="mandatory">Descrizione:</label></h5>
          <textarea [formGroup]="classificazioneForm" formControlName="descrizione" rows="5" class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{
                    'form-control': true,
                    'is-invalid': submitClassificazione && c.descrizione.errors
                    }"></textarea>
          <div *ngIf="submitClassificazione && c.descrizione.errors" class="invalid-feedback" style="display: block;">
              <div *ngIf="c.descrizione.errors.required">
                  Campo obbligatorio
              </div>
          </div>
      </div>
      <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
        Aggiungi
    </button>
      </form>
  </div>
</ng-template>

<ng-template #templateAree>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Inserisci nuova area direzione</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
     (click)="closeModalAree()"
      >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  
  <form [formGroup]="areeForm" (ngSubmit)="onSubmitAreeForm()">
    <div class="col-lg-12 mt-3">
      <input type="hidden" [formGroup]="areeForm" formControlName="id" />

      <div class="form-group row">

        <div class="col-lg-6 col-md-6 col-sm-12 mb-xs-3">
          <label for="descrizione">
            <h5 class="">Descrizione:</h5>
          </label>
          <input [formGroup]="areeForm" formControlName="descrizione" maxlength="255" type="text" class="form-control"
            placeholder="Inserisci la descrizione" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submittedAree && af.descrizione.errors
                      }" />
          <div *ngIf="submittedAree && af.descrizione.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="af.descrizione.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>


        <ng-template #IndiceDellePubblicheAmministrazioni>
          <span>Indice delle Pubbliche Amministrazioni</span>
        </ng-template>
        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="codiceIpa">
            <h5 class="mandatory">
              <a type="button"
              class="help"

              [popover]="IndiceDellePubblicheAmministrazioni"
              placement="bottom"
              triggers="mouseenter:mouseleave">

                <i class="far fa-question-circle text-0083e0 help" style="font-size: 14px;">
                </i>
               </a>
               &nbsp;Codice Ipa:</h5>
          </label>
          <input [formGroup]="areeForm" formControlName="codiceIpa" maxlength="50" type="text" value=""
            class="form-control" placeholder="Inserisci il codice ipa" [ngClass]="{
                      'form-control': true,
                      'is-invalid': submittedAree && af.codiceIpa.errors
                      }" />
          <div *ngIf="submittedAree && af.codiceIpa.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="af.codiceIpa.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>

      </div>

      <div class="form-group row">

        <div class="col-lg-6 col-md-6 col-sm-12 mb-xs-3">
          <label for="struttura">
            <h5 class="mandatory">Struttura:</h5>
          </label>
          <input [formGroup]="areeForm" formControlName="struttura" maxlength="50" type="text" value=""
            class="form-control" placeholder="Inserisci la struttura" [ngClass]="{
                          'form-control': true,
                          'is-invalid': submittedAree && af.struttura.errors
                          }" />
          <div *ngIf="submittedAree && af.struttura.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="af.struttura.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>



        <div class="col-lg-6 col-md-6 col-sm-12">
          <label for="nominativo">
            <h5 class="mandatory">Nominativo:</h5>
          </label>
          <input [formGroup]="areeForm" formControlName="nominativo" maxlength="150" type="text" value=""
            class="form-control" placeholder="Inserisci il nominativo" [ngClass]="{
                              'form-control': true,
                              'is-invalid': submittedAree && af.nominativo.errors
                              }" />
          <div *ngIf="submittedAree && af.nominativo.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="af.nominativo.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>
      </div>

      <div class="form-group row">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <label for="menu">
            <h5>Menu:</h5>
          </label>
          <ng-select bindValue="id" [formGroup]="areeForm" placeholder="Seleziona" bindLabel="code" [items]="menus" formControlName="menu">
          </ng-select>  
        </div>
      </div>

      <div class="form-group row">

        <div class="col-sm-12">
          <label for="descrizioneEstesa">
            <h5 class="mandatory">Descrizione estesa:</h5>
          </label>

          <textarea [formGroup]="areeForm" rows="3" formControlName="descrizioneEstesa" value="" class="form-control"
            placeholder="Inserisci la descrizione estesa" [ngClass]="{
                                  'form-control': true,
                                  'is-invalid': submittedAree && af.descrizioneEstesa.errors
                                  }"></textarea>
          <div *ngIf="submittedAree && af.descrizioneEstesa.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="af.descrizioneEstesa.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>

      </div>


      <div class="form-group col-sm-12 mt-3 " >
        <label for="" class="">
          <h5 class="">
            Area abilitata:
          </h5>

        </label>
        <div>
          <div class="form-check  form-check-inline">

            <input [formGroup]="areeForm" id="abilitato1" formControlName="areaTematicaDisabledSwitch" type="radio" value="1"
              class="form-check-input"  />

            <label for="abilitato1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input [formGroup]="areeForm" id="abilitato2" formControlName="areaTematicaDisabledSwitch" type="radio" value="0"
              class="form-check-input" />
            <label for="abilitato2" class="form-check-label">No</label>
          </div>
        </div>

      </div>

      <div class="form-group col-sm-12 mt-3 " >
        <label for="" class="">
          <h5 class="mandatory">
            Gestisci dati PNRR:
          </h5>

        </label>
        <div>
          <div class="form-check  form-check-inline">

            <input [formGroup]="areeForm" id="gestisciPnrr1" formControlName="gestisciPnrrSwitch" type="radio" value="1"
              class="form-check-input"  />

            <label for="gestisciPnrr1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input [formGroup]="areeForm" id="gestisciPnrr2" formControlName="gestisciPnrrSwitch" type="radio" value="0"
              class="form-check-input" />
            <label for="gestisciPnrr2" class="form-check-label">No</label>
          </div>
        </div>
        <div *ngIf="submittedAree && af.gestisciPnrrSwitch.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="af.gestisciPnrrSwitch.errors.required">
              Campo obbligatorio
            </div>
          </div>
      </div>
      <div class="form-group col-sm-12 mt-3 " >
        <label for="" class="">
          <h5 class="mandatory">
            Gestisci dati non PNRR:
          </h5>

        </label>
        <div>
          <div class="form-check  form-check-inline">

            <input [formGroup]="areeForm" id="gestisciNonPnrr1" formControlName="gestisciNonPnrrSwitch" type="radio" value="1"
              class="form-check-input"  />

            <label for="gestisciNonPnrr1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input [formGroup]="areeForm" id="gestisciNonPnrr2" formControlName="gestisciNonPnrrSwitch" type="radio" value="0"
              class="form-check-input" />
            <label for="gestisciNonPnrr2" class="form-check-label">No</label>
          </div>
        </div>
        <div *ngIf="submittedAree && af.gestisciNonPnrrSwitch.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="af.gestisciNonPnrrSwitch.errors.required">
              Campo obbligatorio
            </div>
          </div>
      </div>

      <div class="form-group col-sm-12 mt-3 " >
        <label for="" class="">
          <h5 class="mandatory">
            Visualizza solo dati della direzione:
          </h5>

        </label>
        <div>
          <div class="form-check  form-check-inline">

            <input [formGroup]="areeForm" id="visualizzaSoloEntitaDirezione1" formControlName="visualizzaSoloEntitaDirezioneSwitch" type="radio" value="1"
              class="form-check-input"  />

            <label for="visualizzaSoloEntitaDirezione1" class="form-check-label">Si</label>
          </div>
          <div class="form-check form-check-inline">
            <input [formGroup]="areeForm" id="visualizzaSoloEntitaDirezione2" formControlName="visualizzaSoloEntitaDirezioneSwitch" type="radio" value="0"
              class="form-check-input" />
            <label for="visualizzaSoloEntitaDirezione2" class="form-check-label">No</label>
          </div>
        </div>
        <div *ngIf="submittedAree && af.visualizzaSoloEntitaDirezioneSwitch.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="af.visualizzaSoloEntitaDirezioneSwitch.errors.required">
              Campo obbligatorio
            </div>
          </div>
      </div>


    </div>
    <div class="col-lg-12 mt-5">
      <hr>
    </div>

    <div class="col-lg-12 mt-4">
      <div class="row justify-content-between">

        <div class="col-lg-6 col-sm-6 col-6 mt-3">
          <h5 for="" class="">Obbiettivi</h5>
        </div>
        <div class="col-lg-1 col-sm-2 col-3 text-center mt-3">
          <a href="javascript:;;" class="bottone-inserisci" (click)="addObbiettivo();"><i class="fas fa-plus"></i></a>
        </div>
      </div>
    </div>

    <div class="col-lg-12 mt-3 table-responsive-lg">
      <table class="table table-hover table-colore">
        <thead class=" ">
          <th>anno</th>
          <th>obbiettivo</th>
          <th class="casella-icona-tx ">elimina</th>
        </thead>
        <tbody>
          <ng-container *ngIf="obbiettivi && obbiettivi.length && obbiettivi.length>0">
            <tr *ngFor="let item of obbiettivi">
              <td>{{item.anno}}</td>
              <td>{{item.descrizione}}</td>
              <td><a (click)="removeObbiettivo(item)" href="javascript:;;" data-toggle="tooltip" data-placement="top"
                  title="elimina"><i class="far fa-trash-alt casella-icona-tx"></i> </a>
              </td>
            </tr>
          </ng-container>
          <tr *ngIf="!(obbiettivi && obbiettivi.length && obbiettivi.length>0)">
            <td colspan="3">Nessun obbiettivo selezionato</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-lg-12 mb-3"><button [disabled]="loading" class="btn btn-lg btn-primary btn-block " type="submit">
        Inserisci
        <img *ngIf="loading" class="pl-3 float-right"
          src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </button></div>
  </form>
</div>
</ng-template>

<ng-template #templateModalObbiettivo>
  <div class="modal-header  text-center">
    <h4 class="modal-title pull-left">Aggiungi obbiettivo</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModalObbiettivo()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">


    <form [formGroup]="obbiettivoForm" (ngSubmit)="onSubmitObbiettivo()">
      <input type="hidden" [formGroup]="obbiettivoForm" formControlName="id" />
      <div class="col-lg-12 mb-3 mt-3">
        <div class="form-group">
          <label for="anno" class="mandatory">Anno:</label>
          <input [formGroup]="obbiettivoForm" formControlName="anno" maxlength="4" type="text" [imask]="maskYear"
            [unmask]="true" class="form-control" placeholder="Inserisci l'anno" [ngClass]="{
                              'form-control': true,
                              'is-invalid': submittedObbiettivo && ob.anno.errors
                              }" />
          <div *ngIf="submittedObbiettivo && ob.anno.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="ob.anno.errors.required">
              Campo obbligatorio
            </div>
            <div *ngIf="ob.anno.errors.minlength">
              Anno deve essere di 4 cifre
            </div>
            <div *ngIf="ob.anno.errors.range">
              Anno deve essere maggiore di {{minYear}}
            </div>
          </div>
        </div>
        <div class="form-group">
          <label for="descrizione" class="mandatory">Descrizione:</label>
          <input [formGroup]="obbiettivoForm" formControlName="descrizione" maxlength="255" type="text"
            class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{
                            'form-control': true,
                            'is-invalid': submittedObbiettivo && ob.descrizione.errors
                            }" />
          <div *ngIf="submittedObbiettivo && ob.descrizione.errors" class="invalid-feedback"
            style="display: block;">
            <div *ngIf="ob.descrizione.errors.required">
              Campo obbligatorio
            </div>
          </div>
        </div>




        <button [disabled]="loading" class="btn btn-lg btn-primary btn-block" type="submit">
          Aggiungi
          <img *ngIf="loading" class="pl-3 float-right"
            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        </button>
      </div>
    </form>
  </div>
</ng-template>

<ng-template #templateTemiPrioritari>
  <div class="modal-header text-center">
    <h4 class="modal-title pull-left">Inserisci nuovo tema prioritario</h4>
    <button
      type="button"
      class="close pull-right"
      aria-label="Close"
     (click)="closeModalTemi()"
      >
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
  <form [formGroup]="temiForm" (ngSubmit)="onSubmitTemiPrioritari()" class=" col-lg-12">
    <input type="hidden" [formGroup]="temiForm" formControlName="id" />
    <div class="form-group">
        <h5><label for="descrizione" class="mandatory">Tema prioritario:</label></h5>
        <input [formGroup]="temiForm" formControlName="descrizione" maxlength="255" type="text" value="" class="form-control" placeholder="Inserisci la descrizione" [ngClass]="{
                'form-control': true,
                'is-invalid': submittedTemi && t.descrizione.errors
                }" />
        <div *ngIf="submittedTemi && t.descrizione.errors" class="invalid-feedback" style="display: block;">
            <div *ngIf="t.descrizione.errors.required">
                Tema prioritario &egrave; obbligatorio
            </div>
        </div>
    </div>
    <button class="btn btn-lg btn-primary btn-block" type="submit" style="margin:15px 0">
        Inserisci
    </button>
</form>
</div>
</ng-template>

